import {useAppSelector} from './redux';

export const useGetAmsIconName = () => {
  const emoduleTypeIconName = useAppSelector(
    ({assets}) => assets.emoduleTypeIconName
  );

  const getAmsIconName = (type: number | null) => {
    return typeof type === 'number' && emoduleTypeIconName?.[type]
      ? emoduleTypeIconName?.[type]
      : 'unknown';
  };

  return getAmsIconName;
};
