import 'leaflet/dist/leaflet.css';

import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
} from '@mui/material';
import {t} from 'i18next';
import update from 'immutability-helper';
import _ from 'lodash';
import {useEffect, useMemo, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {AssetStatus, CommunicationNodeStatus} from '../../../constants/status';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {AlarmModuleNodeListResponse} from '../../../interfaces/AlarmModuleNode';
import {
  CommtracNodeListQuery,
  CommtracNodeListResponse,
} from '../../../interfaces/CommtracNode';
import {
  DashboardEntity,
  DashboardPanelData,
} from '../../../interfaces/Dashboard';
import {NodeListQuery, NodeListResponse} from '../../../interfaces/Node';
import {getNodeKey} from '../../../utils/nodes';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import {Map as MapComponent, MapLayerId} from '../../common/Map';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {AssetsTypeStatusSelect} from '../../selectors/AssetsTypeStatusSelect';
import {NodesTypeStatusSelect} from '../../selectors/NodesTypeStatusSelect';
import {ZoneSelect} from '../../selectors/ZoneSelect';
import {CnWifiNodestData} from '../CnWifiNodes';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import {EmployeesAssetsViewData} from '../EmployeeAssetsGrid';

export interface MapPanelData {
  params: Partial<CommtracNodeListQuery>;
  nodesParams: Partial<NodeListQuery>;
  refreshInterval?: number;
  activeId?: number;
  mapLayers: MapLayerId[];
  mapLevel: number | null;
  availableMapLayers: MapLayerId[];
}

export const getMapPanelData = (): MapPanelData => ({
  params: {
    miner_status: 'checked_in',
    asset_status: 'checked_in',
    node_type: ['asset', 'miner'],
    section_ids: [],
  },
  nodesParams: {
    communication_node_status: 'active',
    wifi_point_status: 'active',
    node_type: ['communication_node', 'wifi_point'],
    section_ids: [],
  },
  activeId: undefined,
  mapLayers: [
    'street',
    'mine',
    'nodes',
    'routing_network',
    'employees',
    'assets',
    'alarms',
  ],
  mapLevel: null,
  availableMapLayers: [
    'street',
    'mine',
    'nodes',
    'routing_network',
    'employees',
    'assets',
    'alarms',
  ],
});

interface Props {
  value?: DashboardPanelData;
  entities?: DashboardEntity[];
  onUpdate?: (value: DashboardPanelData) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
      | 'amsShortTerm'
      | 'amsLongTerm'
      | 'amsLocation'
      | 'amsEmoduleInstallationHistory'
      | 'amsEmoduleSensorHistory'
      | 'amsEmoduleCalibration'
      | 'beltLocationHistoryReport'
      | 'beltHistoryReport'
  ) => void;
}

export const Map = (props: Props) => {
  const [panel] = usePanel();

  const employeeAseetsPanelData = props.entities?.find(
    (e) => e.name === 'Employees/Assets'
  )?.panel?.data as EmployeesAssetsViewData;

  const cnWifiPanelData = props.entities?.find(
    (e) => e.name === 'Communication/Wifi Nodes'
  )?.panel?.data as CnWifiNodestData;

  const [fetchedCommtracNodeData, setFetchedCommtracNodeData] =
    useState<CommtracNodeListResponse>();
  const [fetchedNodesData, setFetchedNodesData] = useState<NodeListResponse>();
  const [fetchedAlarmModuleData, setFetchedAlarmModuleData] =
    useState<AlarmModuleNodeListResponse>();

  const [isFetching, setIsFetching] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const employeeAseetsParams = employeeAseetsPanelData?.commtracNodes?.params;
  const employeeAseetsSelectedNodeIds =
    employeeAseetsPanelData?.commtracNodes?.selectedIds;

  const cnWifiParams = cnWifiPanelData?.nodes?.params;
  const cnWifiSelectedNodeIds = cnWifiPanelData?.nodes?.selectedIds;

  const [config, setConfig] = useState<MapPanelData>(
    !_.isEmpty(props.value)
      ? (props.value as unknown as MapPanelData)
      : getMapPanelData()
  );

  const selectedCommtracNodes = useMemo(() => {
    if (!employeeAseetsSelectedNodeIds) {
      return fetchedCommtracNodeData?.items;
    }
    return (
      fetchedCommtracNodeData?.items.filter((n) =>
        employeeAseetsSelectedNodeIds?.includes(n.id)
      ) ?? []
    );
  }, [employeeAseetsSelectedNodeIds, fetchedCommtracNodeData]);

  const selectedNodes = useMemo(() => {
    if (!cnWifiSelectedNodeIds) {
      return fetchedNodesData?.items;
    }
    return (
      fetchedNodesData?.items.filter((n) =>
        cnWifiSelectedNodeIds?.includes(getNodeKey(n))
      ) ?? []
    );
  }, [cnWifiSelectedNodeIds, fetchedNodesData]);

  const fetchData = async () => {
    setIsFetching(true);
    setErrors([]);

    try {
      const standby =
        !!employeeAseetsPanelData &&
        employeeAseetsPanelData.viewType !== 'commtracNodes';

      {
        const resp = await API.get<CommtracNodeListResponse>(
          `${apiBaseUrl}/commtrac-node`,
          {params: {...config.params, standby, limit: 2000}}
        );
        setFetchedCommtracNodeData(resp.data);
      }
      {
        const resp = await API.get<NodeListResponse>(`${apiBaseUrl}/node`, {
          params: {...config.nodesParams, standby, limit: 2000},
        });
        setFetchedNodesData(resp.data ?? []);
      }
      {
        const resp = await API.get<AlarmModuleNodeListResponse>(
          `${apiBaseUrl}/alarm-module`,
          {
            params: {
              section_ids: config.params.section_ids,
              standby,
              limit: 2000,
            },
          }
        );
        setFetchedAlarmModuleData(resp.data ?? []);
      }
    } catch (e: any) {
      const messages = getMessagesFromApiError(e);
      setErrors(messages);
    }

    setIsFetching(false);
  };

  const [refreshInterval, setRefreshInterval] = useRefreshInterval(
    fetchData,
    props.value?.refreshInterval ?? 30000
  );

  useEffect(() => {
    if (!isFetching) {
      fetchData();
    }
  }, [config.params, config.nodesParams]);

  useEffect(() => {
    // If dashboard contains employee/asset panel we should
    // override filter values with employee/asset filters
    if (employeeAseetsParams) {
      setConfig({...config, params: employeeAseetsParams});
    }
    if (cnWifiParams) {
      setConfig({...config, nodesParams: cnWifiParams});
    }
  }, [employeeAseetsParams, cnWifiParams]);

  useEffect(() => {
    props.onUpdate?.({...props.value, ...config, refreshInterval});
  }, [config, refreshInterval]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <Backdrop open={isFetching} sx={{position: 'absolute', zIndex: 1001}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box display="flex" justifyContent="space-between" gap={1} py={1}>
        <Box
          display="flex"
          height="100%"
          width="100%"
          gap={1}
          visibility={!employeeAseetsPanelData ? 'visible' : 'hidden'}
        >
          <NodesTypeStatusSelect
            fullWidth
            size="small"
            value={[
              config.nodesParams?.node_type &&
              config.nodesParams.node_type.length === 1
                ? config.nodesParams.node_type[0]
                : 'typeAll',
              config.nodesParams?.communication_node_status as string,
            ]}
            onChange={(v) => {
              const type = (v as string[])?.[0] ?? 'typeAll';
              const status = (v as string[])?.[1] ?? 'active';
              const types =
                type === 'typeAll'
                  ? ['communication_node', 'wifi_point']
                  : [type];
              setConfig(
                update(config, {
                  nodesParams: {
                    node_type: {
                      $set: types as ('communication_node' | 'wifi_point')[],
                    },
                    communication_node_status: {
                      $set: status as CommunicationNodeStatus,
                    },
                    wifi_point_status: {
                      $set: status as CommunicationNodeStatus,
                    },
                  },
                })
              );
            }}
          />
          <AssetsTypeStatusSelect
            fullWidth
            size="small"
            value={[
              config.params?.node_type && config.params.node_type.length === 1
                ? config.params.node_type[0]
                : 'typeAll',
              config.params?.asset_status as string,
            ]}
            onChange={(v) => {
              const type = (v as string[])?.[0] ?? 'typeAll';
              const status = (v as string[])?.[1] ?? 'checked_in';
              const types = type === 'typeAll' ? ['asset', 'miner'] : [type];
              setConfig(
                update(config, {
                  params: {
                    node_type: {
                      $set: types as ('asset' | 'miner')[],
                    },
                    asset_status: {
                      $set: status as AssetStatus,
                    },
                    miner_status: {
                      $set: status as AssetStatus,
                    },
                  },
                })
              );
            }}
          />
          <ZoneSelect
            size="small"
            fullWidth
            nullLabel="All Sections"
            value={config.params.section_ids?.[0]}
            onChange={(v) => {
              const sectionIds = v ? [+v] : [];
              setConfig(
                update(config, {
                  params: {
                    section_ids: {
                      $set: sectionIds,
                    },
                  },
                  nodesParams: {
                    section_ids: {
                      $set: sectionIds,
                    },
                  },
                })
              );
            }}
          />
        </Box>

        <Box display="flex" height="100%">
          <ButtonGroup>
            <Button size="small" onClick={fetchData}>
              <RefreshIcon />
            </Button>
            <AutoRefreshSelect
              value={refreshInterval ?? null}
              onChange={setRefreshInterval}
            />
          </ButtonGroup>
        </Box>
      </Box>

      {errors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      <MapComponent
        selectedLevel={config.mapLevel}
        selectedMapLayers={config.mapLayers}
        availableMapLayers={config.availableMapLayers}
        nodes={selectedNodes}
        alarmNodes={fetchedAlarmModuleData?.items}
        commtracNodes={selectedCommtracNodes}
        onSelectMapLayers={(v) => {
          setConfig({
            ...config,
            mapLayers: v,
          });
        }}
        onSelectLevel={(v) => {
          setConfig({
            ...config,
            mapLevel: v,
          });
        }}
        onOpenHistory={props.onOpenHistory}
        disableEditMode={true}
      />
    </Box>
  );
};
