import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {
  Company,
  CompanyInputBody,
  CompanyItemResponse,
  CompanyProduct,
} from '../../interfaces/Company';
import reduxActions from '../../redux/actions';
import {phpTimezoneOptions} from '../../utils/intl';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';
import {CompanyProducts} from './CompanyProducts';

interface Props {
  pk?: Company['id'];
  item?: Company;
  prefetch?: boolean;
  onCancel?: Function;
  onSubmitted?: () => void;
}

export type Products = {
  [K in CompanyProduct]: boolean;
};

export const CompanyItemUpsert = ({
  pk,
  item,
  prefetch,
  onCancel,
  onSubmitted,
}: Props) => {
  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<
    CompanyItemResponse | undefined
  >(_.cloneDeep(item));
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<CompanyItemResponse>(
        `${apiBaseUrl}/company/${pk}`
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!_.isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/
  const currentCompanyId = useAppSelector(({app}) => app.companyId);
  const reduxDispatch = useDispatch();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: CompanyInputBody) => {
    setSubmittedInProgress(true);

    try {
      const endpoint = pk
        ? `${apiBaseUrl}/company/${pk}`
        : `${apiBaseUrl}/company`;
      const resp = pk
        ? await API.patch(endpoint, data)
        : await API.post(endpoint, data);
      const message = `Company has been ${pk ? 'updated' : 'created'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      if (pk === currentCompanyId) {
        reduxActions.assets.setAssets(reduxDispatch, {company: resp.data});
      }
      onSubmitted?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }

    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = useMemo(() => {
    const fields: any = {
      name: yup.string().nullable().required('Field is required'),
      email: yup.string().nullable().required('Field is required'),
      phone: yup.string().nullable().required('Field is required'),
      mine_id: yup.number().nullable().required('Field is required'),
      timezone: yup.string().nullable().required('Field is required'),
      description: yup.string().nullable().required('Field is required'),
      status: yup.string().nullable().required('Field is required'),
      commtrac_enabled: yup.boolean().test((value, item) => {
        const {
          field,
          commtrac_enabled,
          hazard_ai_enabled,
          ams_enabled,
          alarm_enabled,
          proximity_enabled,
          shaft_enabled,
          belt_enabled,
          ventilation_enabled,
        } = item.parent;

        if (
          commtrac_enabled ||
          hazard_ai_enabled ||
          ams_enabled ||
          alarm_enabled ||
          proximity_enabled ||
          shaft_enabled ||
          belt_enabled ||
          ventilation_enabled
        ) {
          return true;
        } else {
          return item.createError({
            message: `At least one product is required`,
            path: field,
          });
        }
      }),
      dashboard_ams_import: yup.boolean().default(false),
      dashboard_commtrac_import: yup.boolean().default(false),
      dashboard_hazardai_import: yup.boolean().default(false),
      dashboard_proximity_import: yup.boolean().default(false),
    };

    return yup.object().shape(fields);
  }, [pk]);

  const getFormikValues = (item?: Company): CompanyInputBody => ({
    name: item?.name ?? null,
    email: item?.email ?? null,
    phone: item?.phone ?? null,
    status: item?.status ?? null,
    timezone: item?.timezone ?? null,
    mine_id: item?.mine_id ?? null,
    description: item?.description ?? null,
    proximity_enabled: !!item?.proximity_enabled,
    commtrac_enabled: !!item?.commtrac_enabled,
    ams_enabled: !!item?.ams_enabled,
    shaft_enabled: !!item?.shaft_enabled,
    belt_enabled: !!item?.belt_enabled,
    ventilation_enabled: !!item?.ventilation_enabled,
    wifi_enabled: !!item?.wifi_enabled,
    hazard_ai_enabled: !!item?.hazard_ai_enabled,
    alarm_enabled: !!item?.alarm_enabled,
    dashboard_ams_import: item?.dashboard_ams_import ?? false,
    dashboard_commtrac_import: item?.dashboard_commtrac_import ?? false,
    dashboard_hazardai_import: item?.dashboard_hazardai_import ?? false,
    dashboard_proximity_import: item?.dashboard_proximity_import ?? false,
  });

  const formik = useFormik<CompanyInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newFormikValues = getFormikValues(fetchedData);
    if (!_.isEqual(newFormikValues, formik.values)) {
      formik.setValues(newFormikValues);
    }
  }, [fetchedData]);

  return (
    <Box component="form" position="relative" onSubmit={formik.handleSubmit}>
      <Backdrop
        open={
          fetchedInProgress || submittedInProgress
          //submittedDashboardInProgress
        }
        sx={{position: 'absolute'}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{my: 2}}>
          {error}
        </Alert>
      ))}

      <Box my={4}>
        <TextField
          value={formik.values.name ?? ''}
          fullWidth
          name="name"
          label="Name"
          size="small"
          error={!!formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          onChange={(event) => {
            formik.setFieldValue('name', event.target.value || null);
          }}
        />
      </Box>

      <Box my={4}>
        <TextField
          value={formik.values.email ?? ''}
          fullWidth
          name="email"
          label="Email"
          size="small"
          error={!!formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
          onChange={(event) => {
            formik.setFieldValue('email', event.target.value || null);
          }}
        />
      </Box>

      <Box my={4}>
        <TextField
          value={formik.values.phone ?? ''}
          fullWidth
          name="phone"
          label="Phone"
          size="small"
          error={!!formik.touched.phone && !!formik.errors.phone}
          helperText={formik.touched.phone && formik.errors.phone}
          onChange={(event) => {
            formik.setFieldValue('phone', event.target.value || null);
          }}
        />
      </Box>

      <Box my={4}>
        <TextField
          value={formik.values.mine_id ?? ''}
          fullWidth
          name="mine_id"
          label="Mine ID"
          type="number"
          size="small"
          error={!!formik.touched.mine_id && !!formik.errors.mine_id}
          helperText={formik.touched.mine_id && formik.errors.mine_id}
          onChange={(event) => {
            formik.setFieldValue('mine_id', event.target.value || null);
          }}
        />
      </Box>

      <Box my={4}>
        <Autocomplete
          value={
            phpTimezoneOptions.find((i) => i.name === formik.values.timezone) ??
            null
          }
          fullWidth
          options={phpTimezoneOptions}
          isOptionEqualToValue={(option, value) => option.name === value?.name}
          getOptionLabel={(option) => option.fullName}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Timezone"
              error={!!formik.touched.timezone && !!formik.errors.timezone}
              helperText={formik.touched.timezone && formik.errors.timezone}
            />
          )}
          onChange={(event, value) =>
            formik.setFieldValue('timezone', value?.name)
          }
        />
      </Box>

      <Box my={4}>
        <TextField
          value={formik.values.status ?? ''}
          fullWidth
          name="status"
          label="Status"
          size="small"
          select
          error={!!formik.touched.status && !!formik.errors.status}
          helperText={formik.touched.status && formik.errors.status}
          onChange={formik.handleChange}
        >
          {[
            {value: 'active', name: 'Active'},
            {value: 'inactive', name: 'Inactive'},
          ].map((i) => (
            <MenuItem key={i.value} value={i.value}>
              {i.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box my={4}>
        <TextField
          value={formik.values.description ?? ''}
          fullWidth
          name="description"
          label="Description"
          size="small"
          multiline
          rows={5}
          error={!!formik.touched.description && !!formik.errors.description}
          helperText={formik.touched.description && formik.errors.description}
          onChange={(event) => {
            formik.setFieldValue('description', event.target.value || null);
          }}
        />
      </Box>

      <Divider>Products</Divider>

      <CompanyProducts formik={formik} />

      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={() => onCancel?.()}>Cancel</Button>

        <LoadingButton
          sx={{ml: 1}}
          variant="contained"
          loading={submittedInProgress}
          type="submit"
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
};
