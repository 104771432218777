import {Dashboard} from '../interfaces/Dashboard';
import {Me} from '../interfaces/Me';

type Action = 'EDIT' | 'DELETE' | 'SHARE';

export const product_options: Record<string, string> = {
  proximity: 'proximity_enabled',
  ams: 'ams_enabled',
  belt: 'belt_enabled',
  connect: 'commtrac_enabled',
  shaft_clearence: 'shaft_enabled',
  ventilation: 'ventilation_enabled',
  hazard_ai: 'hazard_ai_enabled',
  alarm: 'alarm_enabled',
};

export const checkPrivilegeAction = (
  user: Me | null,
  dashboard: Dashboard | undefined,
  action: Action
) => {
  if ((action === 'EDIT' || action === 'DELETE') && dashboard) {
    if (user?.id === dashboard?.user_id) {
      // dashboard that they themselves create or delete.
      return true;
    } else if (
      user?.type_id === 1 &&
      user?.type_id < dashboard?.user_owner?.type_id
    ) {
      //superUser can edit or delete others dashboard
      return true;
    } else if (
      user?.type_id === 2 &&
      user?.type_id < dashboard?.user_owner?.type_id
    ) {
      //strataManger can edit or delete others dashboard
      return true;
    } else if (
      user?.type_id === 3 &&
      user?.type_id < dashboard?.user_owner?.type_id
    ) {
      //clientManger can edit or delete others dashboard
      return true;
    }
  } else if (action === 'SHARE' && dashboard) {
    if (
      user?.id === dashboard?.user_id ||
      (user?.type_id ?? 0) < dashboard?.user_owner?.type_id
    ) {
      // dashboard that they share
      return true;
    }
  }
  return false;
};
