import MenuIcon from '@mui/icons-material/Menu';
import {
  Alert,
  Box,
  IconButton,
} from '@mui/material';
import {t} from 'i18next';
import update from 'immutability-helper';
import { isEmpty } from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

import API, { getMessagesFromApiError } from '../../../api/axios';
import { apiBaseUrl } from '../../../api/urls';
import { useConfiguration, useConfigurations } from '../../../hooks/configuration';
import { useAppSelector } from '../../../hooks/redux';
import { useRefreshInterval } from '../../../hooks/refreshInterval';
import { DashboardPanelData } from '../../../interfaces/Dashboard';
import { EventListResponse, EventSummaryResponse } from '../../../interfaces/Event';
import reduxSelectors from '../../../redux/selectors';
import { getMsFromS } from '../../../utils/datetime';
import { getCompanyProductStatusByType } from '../../../utils/events';
import AccessControl from '../../common/AccessControl';
import { usePanel } from '../../dashboards/entities/DashboardEntityContext';
import { DashboardPanelTitleSlot } from '../DashboardPanelTitleSlot';
import { getShaftClearanceAssetsData, ShaftClearanceAssets, ShaftClearanceAssetsData } from './ShaftClearanceAssets';
import { getShaftClearanceEmployeesData, ShaftClearanceEmployees,ShaftClearanceEmployeesData } from './ShaftClearanceEmployees';
import { getShaftClearanceEventsData,ShaftClearanceEvents, ShaftClearanceEventsData } from './ShaftClearanceEvents';
import {ShaftClearanceStats} from './ShaftClearanceStats';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

interface ShaftClearanceData {
  viewType:
    | 'employees'
    | 'assets'
    | 'events';
  shaftClearanceEmployees: ShaftClearanceEmployeesData;
  shaftClearanceAssets: ShaftClearanceAssetsData;
  shaftClearanceEvents: ShaftClearanceEventsData;
}

const getShaftClearanceData = (): ShaftClearanceData => ({
  viewType: 'employees',
  shaftClearanceEmployees: getShaftClearanceEmployeesData(),
  shaftClearanceAssets: getShaftClearanceAssetsData(),
  shaftClearanceEvents: getShaftClearanceEventsData()
});

export const ShaftClearance = (props: Props) => {
  const isDarkMode = useSelector(reduxSelectors.app.getIsDarkMode);
  const isCompactMode = useAppSelector(reduxSelectors.app.getIsCompactMode);
  const company = useAppSelector(({assets}) => assets.company);
  const configurations = useConfigurations();
  const playedEventIds = useAppSelector(({app}) => app.playedEventIds);

  const [loading, setLoading] = useState<boolean>(false);

  const [config, setConfig] = useState<ShaftClearanceData>(
    !isEmpty(props.value)
      ? props.value as ShaftClearanceData
      : getShaftClearanceData()
  );

  const [fullTabs, setFullTabs] = useState(false);

  /*****************/
  /* event summary */
  /*****************/
  const filteredEventTypeByProduct = (types: string[]) => {
    return types.filter((type) => {
      const productStatus = getCompanyProductStatusByType(
        parseInt(type)
      ).product;
      if (productStatus === 'shaft_enabled') {
        return company?.shaft_enabled;
      } else {
        return false;
      }
    });
  };

  const [fetchedEventsData, setFetchedEventsData] =
    useState<EventListResponse>();
  const [fetchedEventsErrors, setFetchedEventsErrors] = useState<string[]>([]);
  const [fetchedEventsInProgress, setFetchedEventsInProgress] = useState(false);
  const [eventIds, setEventIds] = useState<
    {
      id: number;
      product: string;
    }[]
  >([]);

  const fetchEvents = useCallback(async () => {
    setFetchedEventsInProgress(true);
    setFetchedEventsErrors([]);
    try {
      const type = filteredEventTypeByProduct(config.shaftClearanceEvents.params.type ?? []);
      const params = {
        ...config.shaftClearanceEvents.params,
        type: type.length > 0 ? type : ['501', '503'],
        limit: config.viewType !== 'events' ? 1 : config.shaftClearanceEvents.params.limit,
      };
      const resp = await API.get<EventListResponse>(`${apiBaseUrl}/event`, {
        params,
      });
      setFetchedEventsData(resp.data);
      setEventIds(
        resp?.data?.items?.map(
          (it) =>
            ({
              id: it?.id,
              product: it?.product,
            }) ?? []
        )
      );
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedEventsErrors(messages);
    }
    setFetchedEventsInProgress(false);
  }, [config.viewType, config.shaftClearanceEvents.params]);

  useEffect(() => {
    fetchEvents();
  }, [config.shaftClearanceEvents.params]);

  const eventRefresh = useConfiguration(
    'auto-refresh',
    'event_summary_autorefresh_rate'
  );
  const eventRefreshInterval = eventRefresh?.value
    ? getMsFromS(+eventRefresh?.value)
    : null;
  useRefreshInterval(fetchEvents, eventRefreshInterval);


  const [fetchedEventSummaryData, setFetchedEventSummaryData] =
    useState<EventSummaryResponse>();
  const [fetchedEventSummaryErrors, setFetchedEventSummaryErrors] = useState<
    string[]
  >([]);
  const [fetchedEventSummaryInProgress, setFetchedEventSummaryInProgress] =
    useState(false);

  const fetchEventSummary = useCallback(async () => {
    setFetchedEventSummaryInProgress(true);
    setFetchedEventSummaryErrors([]);
    try {
      const params = {
        date_start: config.shaftClearanceEvents.params.date_start,
        date_end: config.shaftClearanceEvents.params.date_end,
      };
      const resp = await API.get<EventSummaryResponse>(
        `${apiBaseUrl}/event/summary`,
        {params}
      );
      setFetchedEventSummaryData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedEventSummaryErrors(messages);
    }
    setFetchedEventSummaryInProgress(false);
  }, [
    config.shaftClearanceEvents.params.date_start,
    config.shaftClearanceEvents.params.date_end,
    configurations,
    playedEventIds,
  ]);

  const eventSummaryRefresh = useConfiguration(
    'commtrac',
    'commtrac_sound_interval'
  );
  const eventSummaryRefreshInterval = eventSummaryRefresh?.value
    ? getMsFromS(+eventSummaryRefresh?.value)
    : null;
  useRefreshInterval(fetchEventSummary, eventSummaryRefreshInterval);

  useEffect(() => {
    fetchEvents();
    fetchEventSummary();
  }, []);

  useEffect(() => {
    fetchEventSummary();
  }, [config.shaftClearanceEvents.params.date_start, config.shaftClearanceEvents.params.date_end]);

   /****************/
  /* panel config */
  /****************/
  useEffect(() => {
    props.onUpdate?.({...config});
  }, [config]);

  const [panel] = usePanel();

  useEffect(() => {
    if (!isCompactMode) {
      setFullTabs(true);
    } else {
      setFullTabs(false);
    }
  }, [isCompactMode]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
      bgcolor={isDarkMode ? 'background.default' : 'grey.100'}
    >
      <DashboardPanelTitleSlot>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{margin: '8px 0px'}}
          alignItems="center"
        >
          {isCompactMode ? (
            <IconButton
              color="primary"
              onClick={() => {
                setFullTabs(!fullTabs);
              }}
              sx={{position: 'absolute', left: '8px'}}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          <Box>{t(`panels.${panel?.code}`)}</Box>
        </Box>
      </DashboardPanelTitleSlot>
      <Box>
        <ShaftClearanceStats
          activeTab={config.viewType}
          fullTabs={fullTabs}
          eventsCount={fetchedEventsData?.count}
          eventSummaryData={fetchedEventSummaryData}
          isLoading={loading}
          isEventsLoading={fetchedEventsInProgress}
          isEventSummaryLoading={fetchedEventSummaryInProgress}
          onChangeActiveTab={(v: any) => {
            if (config.viewType !== v && !loading) {
              setConfig(
                update(config, {
                  viewType: {
                    $set: v,
                  },
                })
              );
            }
          }}
          onEventSummaryFetch={() => {
            fetchEventSummary();
            fetchEvents();
          }}
        />
      </Box>

      <Box
        position="relative"
        bgcolor={isDarkMode ? '#2E2E2E' : '#FFF'}
      >
        <Box>
          {fetchedEventSummaryErrors.map((error, idx) => (
            <Alert
              key={`error-es-${idx}`}
              severity="error"
              onClose={fetchEventSummary}
            >
              {error}
            </Alert>
          ))}
          {fetchedEventsErrors.map((error, idx) => (
            <Alert
              key={`error-es-${idx}`}
              severity="error"
              onClose={fetchEventSummary}
            >
              {error}
            </Alert>
          ))}
        </Box>
      </Box>
      <Box height="100%" overflow="hidden">
        {config.viewType === "employees" && (
          <AccessControl permissions={['get::/shaft-clearance/employee/grid', 'get::/shaft-clearance/employee/chart', 'get::/shaft-clearance/employee/graph']}>
            <ShaftClearanceEmployees
              value={config.shaftClearanceEmployees}
              onChange={(v: any) => {
                setConfig(
                  update(config, {
                    shaftClearanceEmployees: {
                      $set: v ?? getShaftClearanceEmployeesData(),
                    },
                  })
                )
              }}
              onLoading={setLoading}
            />
          </AccessControl>
        )}
        {config.viewType === "assets" && (
          <AccessControl permissions={['get::/shaft-clearance/asset/grid', 'get::/shaft-clearance/asset/chart', 'get::/shaft-clearance/asset/graph']}>
            <ShaftClearanceAssets
              value={config.shaftClearanceAssets}
              onChange={(v: any) => {
                setConfig(
                  update(config, {
                    shaftClearanceAssets: {
                      $set: v ?? getShaftClearanceAssetsData(),
                    },
                  })
                )
              }}
              onLoading={setLoading}
            />
          </AccessControl>
        )}
        {config.viewType === "events" && (
          <ShaftClearanceEvents
            value={config.shaftClearanceEvents}
            loading={fetchedEventsInProgress}
            fetchedData={fetchedEventsData}
            eventIds={eventIds}
            onRefresh={fetchEvents}
            onChange={(v) =>
              setConfig(
                update(config, {
                  shaftClearanceEvents: {
                    $set: v ?? getShaftClearanceEventsData(),
                  },
                })
              )
            }
          />
        )}
      </Box>
    </Box>
  );
};
