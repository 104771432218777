import {AlarmOutlined} from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Button, IconButton, Tooltip} from '@mui/material';
import dayjs from 'dayjs';

import {AlarmModuleNode} from '../../../interfaces/AlarmModuleNode';
import AccessControl from '../AccessControl';

interface Props {
  item: AlarmModuleNode;
  disableEditMode?: boolean;
  onOpenItem?: () => void;
  onOpenHistory?: () => void;
}

const AlarmTooltipContent = ({
  item,
  disableEditMode,
  onOpenItem,
  onOpenHistory,
}: Props) => {
  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Box>Name: {item.name ?? ''}</Box>
      <Box>ID: {item.id}</Box>
      <Box>Mac Address: {item.mac_address ?? '-'}</Box>
      <Box>
        Status:{' '}
        <Box component="span" textTransform="capitalize">
          {item.status ?? '-'}
        </Box>
      </Box>
      <Box>
        Updated:{' '}
        {item.updated_at
          ? dayjs(item.updated_at).format('YYYY-MM-DD HH:mm:ss') ?? '-'
          : '-'}
      </Box>

      {item?.alarm ? (
        <Box>
          Alarm Date:{' '}
          {item.alarm_at
            ? dayjs(item.alarm_at).format('YYYY-MM-DD HH:mm:ss') ?? '-'
            : '-'}
        </Box>
      ) : null}
      {item?.warning ? (
        <Box>
          Warning Date:{' '}
          {item.warning_at
            ? dayjs(item.warning_at).format('YYYY-MM-DD HH:mm:ss') ?? '-'
            : '-'}
        </Box>
      ) : null}
      <Box display="flex" my={1}>
        <AccessControl
          permissions={[
            'get::/alarm-module/:id(\\d+)/history',
            'get::/alarm-module/:id(\\d+)/log',
          ]}
        >
          <Button
            size="small"
            variant="text"
            sx={{
              color: (theme) => theme.palette.primary.dark,
            }}
            onClick={() => onOpenHistory?.()}
          >
            View History
          </Button>
        </AccessControl>
      </Box>
      <Box
        display="flex"
        gap={1}
        justifyContent="space-between"
        alignItems="center"
      >
        {item?.alarm ? (
          <Tooltip title="Alarm">
            <AlarmOutlined color="error" />
          </Tooltip>
        ) : null}
        {item?.warning ? (
          <Tooltip title="Warning">
            <AlarmOutlined color="warning" />
          </Tooltip>
        ) : null}
        {!disableEditMode ? (
          <AccessControl permissions={['patch::/alarm-module/:id(\\d+)']}>
            <IconButton size="small" onClick={() => onOpenItem?.()}>
              <EditIcon sx={{color: (theme) => theme.palette.primary.dark}} />
            </IconButton>
          </AccessControl>
        ) : null}
      </Box>
    </Box>
  );
};

export default AlarmTooltipContent;
