/* eslint-disable no-bitwise */
import {Box} from '@mui/material';

import {useAppSelector} from '../../../hooks/redux';
import {AMSEModuleNode} from '../../../interfaces/AMSEModuleNode';
import {beltAddressMask} from '../../../interfaces/BeltMonitoringNode';
import {SentroType} from '../../../interfaces/GasMonitoringNode';

interface Props {
  items?: any[];
}

const TempItemTooltipContent = ({items}: Props) => {
  const minerAddressMask = useAppSelector(
    ({assets}) => assets.constants?.miner.address_mask
  );
  const typeIdLabels = useAppSelector(({assets}) => assets.emoduleTypeIdLabel);
  const emoduleChannelLabel = useAppSelector(
    ({assets}) => assets.sentro8ChannelLabel
  );

  let i = 0;
  return (
    <>
      {items?.map((it, no) => {
        console.log(it.emodules);
        if (it.sentro_type === SentroType.SENTRO_1) {
          i++;
        }

        return it?.item_type === 'employee' || it?.item_type === 'asset' ? (
          <Box>
            {no + 1}. Name:{' '}
            {it.type === 'miner' ? it.h_nickname ?? it.name : it.name}, Network
            ID:{' '}
            {it.wifi_enabled
              ? it.mac_address
              : it.type === 'miner' && it.commtrac_external_id
                ? minerAddressMask
                  ? it.commtrac_external_id & minerAddressMask
                  : null
                : it.type === 'asset'
                  ? it.commtrac_external_id
                  : '-'}
            , {it.commtrac_date}
          </Box>
        ) : it?.item_type === 'e_module' ? (
          <Box>
            {no + 1}. SN: {it.serial_number}, Type:{' '}
            {typeIdLabels?.[it?.type ?? 255] ?? '-'}
          </Box>
        ) : it?.item_type === 'ams_sensor' ? (
          it?.sentro_type === SentroType.SENTRO_8 ? (
            it?.emodules?.map((emodule: AMSEModuleNode, index: number) => {
              i++;
              return (
                <Box key={`${index}`}>
                  {i}. SN: {emodule.serial_number}, Ch:{' '}
                  {emoduleChannelLabel && emodule.channel
                    ? emoduleChannelLabel[emodule.channel]
                    : ''}
                  , Type:{' '}
                  {typeIdLabels ? typeIdLabels[emodule.type ?? 255] : '-'}
                </Box>
              );
            })
          ) : (
            <Box>
              {i}. SN: {it.sensor_serial_number}, Type:{' '}
              {typeIdLabels ? typeIdLabels[it.type_id ?? 255] : '-'}
            </Box>
          )
        ) : it?.item_type === 'belt' ? (
          <Box>
            {no + 1}. Name: {it.name}, Network ID:{' '}
            {it?.commtrac_external_id
              ? it?.commtrac_external_id & beltAddressMask
              : '-'}
            {` ${it?.timestamp ?? ''}`}
          </Box>
        ) : it?.item_type === 'belt_location_history' ? (
          <Box>
            {no + 1}. Network ID:{' '}
            {
              // eslint-disable-next-line no-bitwise
              it?.commtrac_external_id & beltAddressMask
            }
            , Closest Node Name: {it?.strongest_cn_name}, Timestamp: {it?.date}
          </Box>
        ) : (
          <Box>
            {no + 1}. Name: {it.name},{' '}
            {it.node_type === 'communication_node'
              ? `Network ID: ${it.cn_external_id}`
              : `IP: ${it.wifi_ip}`}
            ,{' '}
            {it.node_type === 'communication_node'
              ? `${it.cn_last_update ?? '-'}`
              : `${it.wifi_last_response ?? '-'}`}
          </Box>
        );
      })}
    </>
  );
};

export default TempItemTooltipContent;
