import { Box } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Bar, BarChart, CartesianGrid,Legend,ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { ShaftClearanceAssetListQuery, ShaftClearanceEmployeesListQuery } from '../../../interfaces/ShaftClearance';

export interface BarItem {
  data: number;
  name: string;
}

interface Props {
  params: ShaftClearanceEmployeesListQuery | ShaftClearanceAssetListQuery;
  data: BarItem[];
  onChangeFilter: (v: string, filterType: string) => void;
}

export interface BarRef {
  updateBarGraph?: Function;
}

export const BarView = forwardRef<BarRef, Props>(
({
  params,
  data,
  onChangeFilter
}, ref) => {
  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    updateBarGraph: () => updateDimensions()
  }));

  const [height, setHeight] = useState(0);

  const CustomLegend = ({ payload }: any) => {
    return (
      <Box
        sx={{
          width: '100%',
          position: 'absolute',
          height: '50px',
          listStyle: 'none',
          padding: 0,
          transform: 'translate(50%, -40px)',
          "@media (max-width: 462)": {
            display: 'none'
          }
        }}
      >
        {payload.map((entry: any, index: any) => (
          <li key={`item-${index}`} style={{ margin: '0 10px', display: 'flex', alignItems: 'center' }}>
            <div style={{
              width: '16px',
              height: '16px',
              backgroundColor: entry.color,
              marginRight: '5px'
            }} />
            <span style={{color: entry.color, fontSize: '12px'}}>Amount</span>
          </li>
        ))}
      </Box>
    );
  };

  const CustomizedYAxisTick = ({ x, y, payload }: any) => {
    // Split the label into multiple lines
    const label = payload.value;
    const maxCharsPerLine = 12;
    const lines = [];

    for (let i = 0; i < label.length; i += maxCharsPerLine) {
      lines.push(label.substring(i, i + maxCharsPerLine));
    }

    return (
      <g transform={`translate(${x},${y})`}>
        {lines.map((line, index) => (
          <text
            key={index}
            width={20}
            x={4}
            y={index * 15}
            dy={4}
            textAnchor="end"
            fill="#fff"
            fontSize={15}
            style={{
              width: '100px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {line}
          </text>
        ))}
      </g>
    );
  };

  const customizedTooltip = ({ active, payload, label }: { active?: boolean; payload?: any[]; label?: string }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{backgroundColor: '#fff', border: '2px solid #778844', padding: '0px 10px', borderRadius: '5px', color: '#000' }}>
          <p>{label}</p>
          <p style={{fontSize: '14px'}}>Amount: {payload[0].payload.data}</p>
        </div>
      );
    }
    return null;
  };

  const handleBarClick = (data: BarItem) => {
    if (!params.location && !params.section_id) {
      onChangeFilter(data.name.toLowerCase(), "location");
    } else if (!params.section_id) {
      onChangeFilter(data.name, "section_id");
    }
  };

  const updateDimensions = () => {
    const element = document.querySelector('.bar') as HTMLElement;

    if (element) {
      setHeight(element.clientHeight - 120);
    }
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  return (
    <Box
      height="100%"
      width="100%"
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        position: 'relative',
        '& .recharts-surface': {
          padding: '35px 30px 40px 30px'
        },
        '& .chart-container': {
          paddingLeft: '20px'
        }
      }}
    >
      <Box
        className="bar"
        height="100%"
        width="100%"
      >
        {data.length > 0
          ? <ResponsiveContainer width="100%" height={height} minWidth={400}>
              <BarChart
                layout="vertical"
                data={data}
                margin={{ top: 20, right: 30, left: 50, bottom: 5 }}
              >
                <XAxis type="number" />
                <YAxis type="category" dataKey="name" tick={<CustomizedYAxisTick />} />
                <Tooltip content={customizedTooltip} />
                <CartesianGrid strokeDasharray="3 3" />
                <Legend content={<CustomLegend />} />
                <Bar
                  dataKey="data"
                  fill="#7db7ed"
                  onClick={(data) => handleBarClick(data)}
                />
              </BarChart>
            </ResponsiveContainer>
          : <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              No data
            </Box>
          }
      </Box>
    </Box>
  );
});
