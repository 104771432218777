import { Box } from "@mui/material";
import Highcharts from "highcharts";
import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";

export interface PieItem {
  zone_id?: number;
  name: string;
  y: number;
}

interface Props {
  locationData: PieItem[] | [];
  sectionData: PieItem[] | [];
  location?: string;
  onChangeFilter: (v: string, filterType: string) => void;
}

export interface PieRef {
  updatePieChart?: Function;
}

export const PieView = forwardRef<PieRef, Props>(
  ({
    locationData,
    sectionData,
    location,
    onChangeFilter
  }, ref) => {
  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    updatePieChart: () => updateDimensions()
  }));

  const handleSliceClick = useCallback((event: any) => {
    const { point } = event;
    const isOuterPie = point.series.name === "Location Data";

    onChangeFilter(point.name, isOuterPie ? 'location' : 'section_id');
  }, [onChangeFilter]);

  const updateDimensions = () => {
    const element = document.querySelector('.pie') as HTMLElement;
    if (element) {
        const chartData = [{
          type: 'pie',
          name: 'Section Data',
          size: '100%',
          innerSize: '0%',
          tooltip: {
            pointFormat: '<p>ID: {point.zone_id}</p><br><p>#: {point.y}</p><br><p>%: {point.percentage:.1f}%</p>',
            shared: true,
            useHTML: true
          },
          dataLabels: {
            distance: -20,
            enabled: true,
            format: "{point.name} ({point.y})",
            inside: true,
            verticalAlign: "middle",
          },
          data: sectionData,
        }];
        if (!location) {
          chartData.push({
            type: 'pie',
            name: 'Location Data',
            size: '40%',
            innerSize: '0',
            tooltip: {
              pointFormat: '<p>#: {point.y}</p><br><p>%: {point.percentage:.1f}%</p>',
              shared: true,
              useHTML: true
            },
            dataLabels: {
              distance: -20,
              enabled: true,
              format: "{point.name} ({point.y})",
              inside: true,
              verticalAlign: "middle",
            },
            data: locationData,
          })
        }
        const chartOptions =  {
          chart: {
              type: 'pie',
              backgroundColor: 'none',
              width: element.clientWidth,
              height: element.clientHeight - 100,
          },
          credits: {
              enabled: false,
          },
          plotOptions: {
              pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  dataLabels: {
                      enabled: true,
                  },
                  point: {
                    events: {
                      click: handleSliceClick,
                    },
                  },
              },
          },
          series: chartData
        };

        Highcharts.chart(element, chartOptions as Highcharts.Options);
      }
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);


  return (
    locationData.length > 0 && sectionData.length > 0
      ? <Box
          className="pie"
          sx={{
            width: '100%',
            height: '100%',
            '& .highcharts-title': {
              display: 'none'
            }
          }}
        >
        </Box>
      : <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          No data
        </Box>
  )
});
