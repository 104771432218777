import CodeIcon from '@mui/icons-material/Code';
import {Box, BoxProps} from '@mui/material';
import {Resizable} from 're-resizable';
import {ReactNode} from 'react';

import {useAppSelector} from '../../hooks/redux';
import reduxSelectors from '../../redux/selectors';

interface Props extends Omit<BoxProps, 'left'> {
  left: ReactNode;
  onResizeStop?: () => void;
}

export const ResizableColumns = ({left, onResizeStop, children, ...props}: Props) => {
  const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);

  return (
    <Box display="flex" height="100%" width="100%" overflow="hidden" {...props}>
      <Resizable
        defaultSize={{
          width: '50%',
          height: '100%',
        }}
        maxWidth="100%"
        minWidth="24px"
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
        enable={{right: true}}
        handleStyles={{
          right: {
            width: '24px',
            right: 0,
            zIndex: 1000,
            opacity: 1,
          },
        }}
        handleComponent={{
          right: (
            <Box
              display="flex"
              height="100%"
              bgcolor={isDarkMode ? 'background.default' : 'grey.100'}
            >
              <Box
                display="flex"
                alignItems="center"
                bgcolor={isDarkMode ? '#2E2E2E' : '#FFF'}
                height="100%"
              >
                <CodeIcon color="primary" />
              </Box>
            </Box>
          ),
        }}
        onResizeStop={() => {
          onResizeStop?.();
        }}
      >
        <Box display="flex" height="100%" overflow="hidden" width="100%">
          <Box width="100%" height="100%" overflow="hidden">
            {left}
          </Box>
        </Box>
      </Resizable>

      <Box display="flex" width="100%" overflow="hidden" minWidth="1px">
        <Box width="100%" height="100%" overflow="hidden">
          {children}
        </Box>
      </Box>
    </Box>
  );
};
