import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import {useMemo} from 'react';

import {useAppSelector} from '../../hooks/redux';
import {DashboardDefault, ProductKey} from '../../interfaces/Dashboard';

interface Props {
  formik: any;
}

export const CompanyProducts = ({formik}: Props) => {
  const defaultDashboards: DashboardDefault[] = useAppSelector(
    ({assets}) => assets.default_dashboard
  );

  const allowConnect = useMemo(() => {
    return formik.values.commtrac_enabled || !formik.values.hazard_ai_enabled;
  }, [formik.values]);

  const allowHazardAI = useMemo(() => {
    return (
      formik.values.hazard_ai_enabled ||
      (!formik.values.commtrac_enabled &&
        !formik.values.ams_enabled &&
        !formik.values.alarm_enabled &&
        !formik.values.shaft_enabled &&
        !formik.values.belt_enabled &&
        !formik.values.ventilation_enabled)
    );
  }, [formik.values]);

  const allowGasMonitoring = useMemo(() => {
    return (
      formik.values.ams_enabled ||
      (formik.values.commtrac_enabled && !formik.values.hazard_ai_enabled)
    );
  }, [formik.values]);

  const allowAlarm = useMemo(() => {
    return (
      formik.values.alarm_enabled ||
      (formik.values.commtrac_enabled && !formik.values.hazard_ai_enabled)
    );
  }, [formik.values]);

  const allowProximity = true;

  const allowShaftClearance = useMemo(() => {
    return (
      formik.values.shaft_enabled ||
      (formik.values.commtrac_enabled && !formik.values.hazard_ai_enabled)
    );
  }, [formik.values]);

  const allowBelt = useMemo(() => {
    return (
      formik.values.belt_enabled ||
      (formik.values.commtrac_enabled && !formik.values.hazard_ai_enabled)
    );
  }, [formik.values]);

  const allowVentilation = useMemo(() => {
    return (
      formik.values.ventilation_enabled ||
      (formik.values.ams_enabled && !formik.values.hazard_ai_enabled)
    );
  }, [formik.values]);

  const onConnectChange = (_: any, value: boolean) => {
    if (!value) {
      formik.setFieldValue('alarm_enabled', false);
      formik.setFieldValue('ams_enabled', false);
      formik.setFieldValue('shaft_enabled', false);
      formik.setFieldValue('belt_enabled', false);
      formik.setFieldValue('ventilation_enabled', false);
    } else {
      formik.setFieldValue('hazard_ai_enabled', false);
    }

    formik.setFieldValue('commtrac_enabled', value);
  };

  const onGasMoniroringChange = (_: any, value: boolean) => {
    formik.setFieldValue('ams_enabled', value);

    if (!value) {
      formik.setFieldValue('ventilation_enabled', false);
    }
  };

  const allowedImportDashboardFlags = useMemo(() => {
    //
    return defaultDashboards
      .filter(
        (el) =>
          formik.values[
            `${el.product === 'connect' ? 'commtrac' : el.product}_enabled`
          ]
      )
      .map((el) => el.product);
  }, [formik.values, defaultDashboards]);

  return (
    <Box my={4}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="Connect enabled"
                disabled={!allowConnect}
                control={
                  <Switch
                    checked={!!formik.values.commtrac_enabled}
                    onChange={onConnectChange}
                  />
                }
              />
            </Grid>
            {allowedImportDashboardFlags.includes('connect' as ProductKey) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  name="dashboard_commtrac_import"
                  control={
                    <Checkbox
                      onChange={(_, value) =>
                        formik.setFieldValue('dashboard_commtrac_import', value)
                      }
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="HazardAI enabled"
                disabled={!allowHazardAI}
                control={
                  <Switch
                    checked={!!formik.values.hazard_ai_enabled}
                    onChange={(_, value) =>
                      formik.setFieldValue('hazard_ai_enabled', value)
                    }
                  />
                }
              />
            </Grid>
            {allowedImportDashboardFlags.includes(
              'hazard_ai' as ProductKey
            ) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox
                      onChange={(_, value) =>
                        formik.setFieldValue('dashboard_hazardai_import', value)
                      }
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="Gas Monitoring enabled"
                disabled={!allowGasMonitoring}
                control={
                  <Switch
                    checked={!!formik.values.ams_enabled}
                    onChange={onGasMoniroringChange}
                  />
                }
              />
            </Grid>
            {allowedImportDashboardFlags.includes('ams' as ProductKey) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox
                      onChange={(_, value) =>
                        formik.setFieldValue('dashboard_ams_import', value)
                      }
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="Alarm Module enabled"
                disabled={!allowAlarm}
                control={
                  <Switch
                    checked={!!formik.values.alarm_enabled}
                    onChange={(_, value) =>
                      formik.setFieldValue('alarm_enabled', value)
                    }
                  />
                }
              />
            </Grid>
            {allowedImportDashboardFlags.includes('alarm' as ProductKey) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox
                      onChange={(_, value) =>
                        formik.setFieldValue('dashboard_alarm_import', value)
                      }
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="Proximity enabled"
                disabled={!allowProximity}
                control={
                  <Switch
                    checked={!!formik.values.proximity_enabled}
                    onChange={(_, value) =>
                      formik.setFieldValue('proximity_enabled', value)
                    }
                  />
                }
              />
            </Grid>
            {allowedImportDashboardFlags.includes(
              'proximity' as ProductKey
            ) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox
                      onChange={(_, value) =>
                        formik.setFieldValue(
                          'dashboard_proximity_import',
                          value
                        )
                      }
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="Shaft Clearence enabled"
                disabled={!allowShaftClearance}
                control={
                  <Switch
                    checked={!!formik.values.shaft_enabled}
                    onChange={(_, value) =>
                      formik.setFieldValue('shaft_enabled', value)
                    }
                  />
                }
              />
            </Grid>
            {allowedImportDashboardFlags.includes('shaft' as ProductKey) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox
                      onChange={(_, value) =>
                        formik.setFieldValue('dashboard_shaft_import', value)
                      }
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="Belt Monitoring enabled"
                disabled={!allowBelt}
                control={
                  <Switch
                    checked={!!formik.values.belt_enabled}
                    onChange={(_, value) =>
                      formik.setFieldValue('belt_enabled', value)
                    }
                  />
                }
              />
            </Grid>
            {allowedImportDashboardFlags.includes('belt' as ProductKey) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox
                      onChange={(_, value) =>
                        formik.setFieldValue('dashboard_belt_import', value)
                      }
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                label="Ventilation enabled"
                disabled={!allowVentilation}
                control={
                  <Switch
                    checked={!!formik.values.ventilation_enabled}
                    onChange={(_, value) =>
                      formik.setFieldValue('ventilation_enabled', value)
                    }
                  />
                }
              />
            </Grid>
            {allowedImportDashboardFlags.includes(
              'ventilation' as ProductKey
            ) && (
              <Grid item xs={6}>
                <FormControlLabel
                  label="Include Dashboard"
                  control={
                    <Checkbox
                      onChange={(_, value) =>
                        formik.setFieldValue(
                          'dashboard_ventilation_import',
                          value
                        )
                      }
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Typography color="error" variant="caption">
          {formik.errors.commtrac_enabled}
        </Typography>
      </Grid>
    </Box>
  );
};
