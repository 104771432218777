import {Icon} from 'leaflet';
import mapValues from 'lodash/mapValues';
import {cloneElement} from 'react';

interface ColoredIcons {
  [k: string]: any;
}

export enum iconName {
  COMMUNICATION_NODE = 'communicationNodes',
  MINER = 'miners',
  ASSET_EVENT = 'assetEvents',
  ASSET = 'assets',
  FAN = 'fans',
  BELT = 'belts',
  // AMS
  AMS_UNKNOWN = 'unknown',
  AMS_AMB = 'amb',
  AMS_ANA = 'ana',
  AMS_CH4 = 'ch4',
  AMS_CL2 = 'cl2',
  AMS_CO = 'co',
  AMS_CO2 = 'co2',
  AMS_EMPTY = 'empty',
  AMS_H2 = 'h2',
  AMS_H2S = 'h2s',
  AMS_HUM = 'hum',
  AMS_FLOW = 'flow',
  AMS_NO = 'no',
  AMS_NO2 = 'no2',
  AMS_O2 = 'o2',
  AMS_SO2 = 'so2',
  AMS_SW = 'sw',
  AMS_PRE = 'pre',
  AMS_NH3 = 'nh3',
  AMS_TEM = 'tem',
  AMS_SENTRO_8 = 'sentro_8',
  //
  WIFI = 'wifi',
  ALARM = 'alarm',
  SAFEYE_NODE = 'safeyeNodes',
  VISION = 'vision',
  PERSON = 'person',
}

export const COLORED_ICONS: {
  [value in iconName]: ColoredIcons;
} = {
  [iconName.COMMUNICATION_NODE]: {
    default: require('../images/communication-nodes/communication-node-default.png'),
    blue: require('../images/communication-nodes/communication-node-blue.png'),
    brown: require('../images/communication-nodes/communication-node-brown.png'),
    cornsilk: require('../images/communication-nodes/communication-node-cornsilk.png'),
    cyan: require('../images/communication-nodes/communication-node-cyan.png'),
    green: require('../images/communication-nodes/communication-node-green.png'),
    dark_blue: require('../images/communication-nodes/communication-node-dark_blue.png'),
    gold: require('../images/communication-nodes/communication-node-gold.png'),
    gray: require('../images/communication-nodes/communication-node-gray.png'),
    orchid: require('../images/communication-nodes/communication-node-orchid.png'),
    pink: require('../images/communication-nodes/communication-node-pink.png'),
    red: require('../images/communication-nodes/communication-node-red.png'),
    yellow: require('../images/communication-nodes/communication-node-yellow.png'),
    orange: require('../images/communication-nodes/communication-node-orange.png'),
    duplicate: require('../images/other/duplicate_icon.png'),
  } as ColoredIcons,
  [iconName.MINER]: {
    default: require('../images/miners/miner-default.png'),
    blue: require('../images/miners/miner-blue.png'),
    brown: require('../images/miners/miner-brown.png'),
    cornsilk: require('../images/miners/miner-cornsilk.png'),
    cyan: require('../images/miners/miner-cyan.png'),
    green: require('../images/miners/miner-green.png'),
    dark_blue: require('../images/miners/miner-dark_blue.png'),
    gold: require('../images/miners/miner-gold.png'),
    gray: require('../images/miners/miner-gray.png'),
    orchid: require('../images/miners/miner-orchid.png'),
    pink: require('../images/miners/miner-pink.png'),
    red: require('../images/miners/miner-red.png'),
    yellow: require('../images/miners/miner-yellow.png'),
    orange: require('../images/miners/miner-orange.png'),
  } as ColoredIcons,
  [iconName.ASSET_EVENT]: {
    default: require('../images/asset-events/asset-event-default.png'),
    blue: require('../images/asset-events/asset-event-blue.png'),
    brown: require('../images/asset-events/asset-event-brown.png'),
    cornsilk: require('../images/asset-events/asset-event-cornsilk.png'),
    cyan: require('../images/asset-events/asset-event-cyan.png'),
    green: require('../images/asset-events/asset-event-green.png'),
    dark_blue: require('../images/asset-events/asset-event-dark_blue.png'),
    gold: require('../images/asset-events/asset-event-gold.png'),
    gray: require('../images/asset-events/asset-event-gray.png'),
    orchid: require('../images/asset-events/asset-event-orchid.png'),
    pink: require('../images/asset-events/asset-event-pink.png'),
    red: require('../images/asset-events/asset-event-red.png'),
    yellow: require('../images/asset-events/asset-event-yellow.png'),
    orange: require('../images/asset-events/asset-event-orange.png'),
  } as ColoredIcons,
  [iconName.ASSET]: {
    default: require('../images/assets/asset-default.png'),
    blue: require('../images/assets/asset-blue.png'),
    brown: require('../images/assets/asset-brown.png'),
    cornsilk: require('../images/assets/asset-cornsilk.png'),
    cyan: require('../images/assets/asset-cyan.png'),
    green: require('../images/assets/asset-green.png'),
    dark_blue: require('../images/assets/asset-dark_blue.png'),
    gold: require('../images/assets/asset-gold.png'),
    gray: require('../images/assets/asset-gray.png'),
    orchid: require('../images/assets/asset-orchid.png'),
    pink: require('../images/assets/asset-pink.png'),
    red: require('../images/assets/asset-red.png'),
    yellow: require('../images/assets/asset-yellow.png'),
    orange: require('../images/assets/asset-orange.png'),
  } as ColoredIcons,
  [iconName.FAN]: {
    healthy: require('../images/fans/fan-healthy.png'),
    blue: require('../images/fans/fan-blue.png'),
    brown: require('../images/fans/fan-brown.png'),
    cornsilk: require('../images/fans/fan-cornsilk.png'),
    cyan: require('../images/fans/fan-cyan.png'),
    green: require('../images/fans/fan-green.png'),
    dark_blue: require('../images/fans/fan-dark_blue.png'),
    gold: require('../images/fans/fan-gold.png'),
    gray: require('../images/fans/fan-gray.png'),
    orchid: require('../images/fans/fan-orchid.png'),
    pink: require('../images/fans/fan-pink.png'),
    red: require('../images/fans/fan-red.png'),
    yellow: require('../images/fans/fan-yellow.png'),
    orange: require('../images/fans/fan-orange.png'),
  } as ColoredIcons,
  [iconName.BELT]: {
    healthy: require('../images/belts/belt-healthy.png'),
    inactive: require('../images/belts/belt-inactive.png'),
    blue: require('../images/belts/belt-blue.png'),
    brown: require('../images/belts/belt-brown.png'),
    cornsilk: require('../images/belts/belt-cornsilk.png'),
    cyan: require('../images/belts/belt-cyan.png'),
    green: require('../images/belts/belt-green.png'),
    dark_blue: require('../images/belts/belt-dark_blue.png'),
    gold: require('../images/belts/belt-gold.png'),
    gray: require('../images/belts/belt-gray.png'),
    orchid: require('../images/belts/belt-orchid.png'),
    pink: require('../images/belts/belt-pink.png'),
    red: require('../images/belts/belt-red.png'),
    yellow: require('../images/belts/belt-yellow.png'),
    orange: require('../images/belts/belt-orange.png'),
  } as ColoredIcons,
  [iconName.AMS_UNKNOWN]: {
    health: require('../images/ams/sensors/unknown/health.png'),
    blue: require('../images/ams/sensors/unknown/blue.png'),
    brown: require('../images/ams/sensors/unknown/brown.png'),
    cornsilk: require('../images/ams/sensors/unknown/cornsilk.png'),
    cyan: require('../images/ams/sensors/unknown/cyan.png'),
    green: require('../images/ams/sensors/unknown/green.png'),
    dark_blue: require('../images/ams/sensors/unknown/dark_blue.png'),
    gold: require('../images/ams/sensors/unknown/gold.png'),
    gray: require('../images/ams/sensors/unknown/gray.png'),
    orchid: require('../images/ams/sensors/unknown/orchid.png'),
    pink: require('../images/ams/sensors/unknown/pink.png'),
    red: require('../images/ams/sensors/unknown/red.png'),
    yellow: require('../images/ams/sensors/unknown/yellow.png'),
    orange: require('../images/ams/sensors/unknown/orange.png'),
  } as ColoredIcons,
  [iconName.AMS_AMB]: {
    blue: require('../images/ams/sensors/amb/blue.png'),
    brown: require('../images/ams/sensors/amb/brown.png'),
    cornsilk: require('../images/ams/sensors/amb/cornsilk.png'),
    cyan: require('../images/ams/sensors/amb/cyan.png'),
    dark_blue: require('../images/ams/sensors/amb/dark_blue.png'),
    gold: require('../images/ams/sensors/amb/gold.png'),
    gray: require('../images/ams/sensors/amb/gray.png'),
    green: require('../images/ams/sensors/amb/green.png'),
    orchid: require('../images/ams/sensors/amb/orchid.png'),
    pink: require('../images/ams/sensors/amb/pink.png'),
    red: require('../images/ams/sensors/amb/red.png'),
    yellow: require('../images/ams/sensors/amb/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_ANA]: {
    blue: require('../images/ams/sensors/ana/blue.png'),
    brown: require('../images/ams/sensors/ana/brown.png'),
    cornsilk: require('../images/ams/sensors/ana/cornsilk.png'),
    cyan: require('../images/ams/sensors/ana/cyan.png'),
    dark_blue: require('../images/ams/sensors/ana/dark_blue.png'),
    gold: require('../images/ams/sensors/ana/gold.png'),
    gray: require('../images/ams/sensors/ana/gray.png'),
    green: require('../images/ams/sensors/ana/green.png'),
    orchid: require('../images/ams/sensors/ana/orchid.png'),
    pink: require('../images/ams/sensors/ana/pink.png'),
    red: require('../images/ams/sensors/ana/red.png'),
    yellow: require('../images/ams/sensors/ana/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_CH4]: {
    blue: require('../images/ams/sensors/ch4/blue.png'),
    brown: require('../images/ams/sensors/ch4/brown.png'),
    cornsilk: require('../images/ams/sensors/ch4/cornsilk.png'),
    cyan: require('../images/ams/sensors/ch4/cyan.png'),
    dark_blue: require('../images/ams/sensors/ch4/dark_blue.png'),
    gold: require('../images/ams/sensors/ch4/gold.png'),
    gray: require('../images/ams/sensors/ch4/gray.png'),
    green: require('../images/ams/sensors/ch4/green.png'),
    orchid: require('../images/ams/sensors/ch4/orchid.png'),
    pink: require('../images/ams/sensors/ch4/pink.png'),
    red: require('../images/ams/sensors/ch4/red.png'),
    yellow: require('../images/ams/sensors/ch4/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_CL2]: {
    blue: require('../images/ams/sensors/cl2/blue.png'),
    brown: require('../images/ams/sensors/cl2/brown.png'),
    cornsilk: require('../images/ams/sensors/cl2/cornsilk.png'),
    cyan: require('../images/ams/sensors/cl2/cyan.png'),
    dark_blue: require('../images/ams/sensors/cl2/dark_blue.png'),
    gold: require('../images/ams/sensors/cl2/gold.png'),
    gray: require('../images/ams/sensors/cl2/gray.png'),
    green: require('../images/ams/sensors/cl2/green.png'),
    orchid: require('../images/ams/sensors/cl2/orchid.png'),
    pink: require('../images/ams/sensors/cl2/pink.png'),
    red: require('../images/ams/sensors/cl2/red.png'),
    yellow: require('../images/ams/sensors/cl2/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_CO]: {
    blue: require('../images/ams/sensors/co/blue.png'),
    brown: require('../images/ams/sensors/co/brown.png'),
    cornsilk: require('../images/ams/sensors/co/cornsilk.png'),
    cyan: require('../images/ams/sensors/co/cyan.png'),
    dark_blue: require('../images/ams/sensors/co/dark_blue.png'),
    gold: require('../images/ams/sensors/co/gold.png'),
    gray: require('../images/ams/sensors/co/gray.png'),
    green: require('../images/ams/sensors/co/green.png'),
    orchid: require('../images/ams/sensors/co/orchid.png'),
    pink: require('../images/ams/sensors/co/pink.png'),
    red: require('../images/ams/sensors/co/red.png'),
    yellow: require('../images/ams/sensors/co/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_CO2]: {
    blue: require('../images/ams/sensors/co2/blue.png'),
    brown: require('../images/ams/sensors/co2/brown.png'),
    cornsilk: require('../images/ams/sensors/co2/cornsilk.png'),
    cyan: require('../images/ams/sensors/co2/cyan.png'),
    dark_blue: require('../images/ams/sensors/co2/dark_blue.png'),
    gold: require('../images/ams/sensors/co2/gold.png'),
    gray: require('../images/ams/sensors/co2/gray.png'),
    green: require('../images/ams/sensors/co2/green.png'),
    orchid: require('../images/ams/sensors/co2/orchid.png'),
    pink: require('../images/ams/sensors/co2/pink.png'),
    red: require('../images/ams/sensors/co2/red.png'),
    yellow: require('../images/ams/sensors/co2/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_EMPTY]: {
    blue: require('../images/ams/sensors/empty/blue.png'),
    brown: require('../images/ams/sensors/empty/brown.png'),
    cornsilk: require('../images/ams/sensors/empty/cornsilk.png'),
    cyan: require('../images/ams/sensors/empty/cyan.png'),
    dark_blue: require('../images/ams/sensors/empty/dark_blue.png'),
    gold: require('../images/ams/sensors/empty/gold.png'),
    gray: require('../images/ams/sensors/empty/gray.png'),
    green: require('../images/ams/sensors/empty/green.png'),
    orchid: require('../images/ams/sensors/empty/orchid.png'),
    pink: require('../images/ams/sensors/empty/pink.png'),
    red: require('../images/ams/sensors/empty/red.png'),
    yellow: require('../images/ams/sensors/empty/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_H2]: {
    blue: require('../images/ams/sensors/h2/blue.png'),
    brown: require('../images/ams/sensors/h2/brown.png'),
    cornsilk: require('../images/ams/sensors/h2/cornsilk.png'),
    cyan: require('../images/ams/sensors/h2/cyan.png'),
    dark_blue: require('../images/ams/sensors/h2/dark_blue.png'),
    gold: require('../images/ams/sensors/h2/gold.png'),
    gray: require('../images/ams/sensors/h2/gray.png'),
    green: require('../images/ams/sensors/h2/green.png'),
    orchid: require('../images/ams/sensors/h2/orchid.png'),
    pink: require('../images/ams/sensors/h2/pink.png'),
    red: require('../images/ams/sensors/h2/red.png'),
    yellow: require('../images/ams/sensors/h2/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_H2S]: {
    blue: require('../images/ams/sensors/h2s/blue.png'),
    brown: require('../images/ams/sensors/h2s/brown.png'),
    cornsilk: require('../images/ams/sensors/h2s/cornsilk.png'),
    cyan: require('../images/ams/sensors/h2s/cyan.png'),
    dark_blue: require('../images/ams/sensors/h2s/dark_blue.png'),
    gold: require('../images/ams/sensors/h2s/gold.png'),
    gray: require('../images/ams/sensors/h2s/gray.png'),
    green: require('../images/ams/sensors/h2s/green.png'),
    orchid: require('../images/ams/sensors/h2s/orchid.png'),
    pink: require('../images/ams/sensors/h2s/pink.png'),
    red: require('../images/ams/sensors/h2s/red.png'),
    yellow: require('../images/ams/sensors/h2s/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_HUM]: {
    blue: require('../images/ams/sensors/hum/blue.png'),
    brown: require('../images/ams/sensors/hum/brown.png'),
    cornsilk: require('../images/ams/sensors/hum/cornsilk.png'),
    cyan: require('../images/ams/sensors/hum/cyan.png'),
    dark_blue: require('../images/ams/sensors/hum/dark_blue.png'),
    gold: require('../images/ams/sensors/hum/gold.png'),
    gray: require('../images/ams/sensors/hum/gray.png'),
    green: require('../images/ams/sensors/hum/green.png'),
    orchid: require('../images/ams/sensors/hum/orchid.png'),
    pink: require('../images/ams/sensors/hum/pink.png'),
    red: require('../images/ams/sensors/hum/red.png'),
    yellow: require('../images/ams/sensors/hum/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_FLOW]: {
    blue: require('../images/ams/sensors/flow/blue.png'),
    brown: require('../images/ams/sensors/flow/brown.png'),
    cornsilk: require('../images/ams/sensors/flow/cornsilk.png'),
    cyan: require('../images/ams/sensors/flow/cyan.png'),
    dark_blue: require('../images/ams/sensors/flow/dark_blue.png'),
    gold: require('../images/ams/sensors/flow/gold.png'),
    gray: require('../images/ams/sensors/flow/gray.png'),
    green: require('../images/ams/sensors/flow/green.png'),
    orchid: require('../images/ams/sensors/flow/orchid.png'),
    pink: require('../images/ams/sensors/flow/pink.png'),
    red: require('../images/ams/sensors/flow/red.png'),
    yellow: require('../images/ams/sensors/flow/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_NO]: {
    blue: require('../images/ams/sensors/no/blue.png'),
    brown: require('../images/ams/sensors/no/brown.png'),
    cornsilk: require('../images/ams/sensors/no/cornsilk.png'),
    cyan: require('../images/ams/sensors/no/cyan.png'),
    dark_blue: require('../images/ams/sensors/no/dark_blue.png'),
    gold: require('../images/ams/sensors/no/gold.png'),
    gray: require('../images/ams/sensors/no/gray.png'),
    green: require('../images/ams/sensors/no/green.png'),
    orchid: require('../images/ams/sensors/no/orchid.png'),
    pink: require('../images/ams/sensors/no/pink.png'),
    red: require('../images/ams/sensors/no/red.png'),
    yellow: require('../images/ams/sensors/no/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_NO2]: {
    blue: require('../images/ams/sensors/no2/blue.png'),
    brown: require('../images/ams/sensors/no2/brown.png'),
    cornsilk: require('../images/ams/sensors/no2/cornsilk.png'),
    cyan: require('../images/ams/sensors/no2/cyan.png'),
    dark_blue: require('../images/ams/sensors/no2/dark_blue.png'),
    gold: require('../images/ams/sensors/no2/gold.png'),
    gray: require('../images/ams/sensors/no2/gray.png'),
    green: require('../images/ams/sensors/no2/green.png'),
    orchid: require('../images/ams/sensors/no2/orchid.png'),
    pink: require('../images/ams/sensors/no2/pink.png'),
    red: require('../images/ams/sensors/no2/red.png'),
    yellow: require('../images/ams/sensors/no2/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_O2]: {
    blue: require('../images/ams/sensors/o2/blue.png'),
    brown: require('../images/ams/sensors/o2/brown.png'),
    cornsilk: require('../images/ams/sensors/o2/cornsilk.png'),
    cyan: require('../images/ams/sensors/o2/cyan.png'),
    dark_blue: require('../images/ams/sensors/o2/dark_blue.png'),
    gold: require('../images/ams/sensors/o2/gold.png'),
    gray: require('../images/ams/sensors/o2/gray.png'),
    green: require('../images/ams/sensors/o2/green.png'),
    orchid: require('../images/ams/sensors/o2/orchid.png'),
    pink: require('../images/ams/sensors/o2/pink.png'),
    red: require('../images/ams/sensors/o2/red.png'),
    yellow: require('../images/ams/sensors/o2/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_SO2]: {
    blue: require('../images/ams/sensors/so2/blue.png'),
    brown: require('../images/ams/sensors/so2/brown.png'),
    cornsilk: require('../images/ams/sensors/so2/cornsilk.png'),
    cyan: require('../images/ams/sensors/so2/cyan.png'),
    dark_blue: require('../images/ams/sensors/so2/dark_blue.png'),
    gold: require('../images/ams/sensors/so2/gold.png'),
    gray: require('../images/ams/sensors/so2/gray.png'),
    green: require('../images/ams/sensors/so2/green.png'),
    orchid: require('../images/ams/sensors/so2/orchid.png'),
    pink: require('../images/ams/sensors/so2/pink.png'),
    red: require('../images/ams/sensors/so2/red.png'),
    yellow: require('../images/ams/sensors/so2/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_SW]: {
    blue: require('../images/ams/sensors/sw/blue.png'),
    brown: require('../images/ams/sensors/sw/brown.png'),
    cornsilk: require('../images/ams/sensors/sw/cornsilk.png'),
    cyan: require('../images/ams/sensors/sw/cyan.png'),
    dark_blue: require('../images/ams/sensors/sw/dark_blue.png'),
    gold: require('../images/ams/sensors/sw/gold.png'),
    gray: require('../images/ams/sensors/sw/gray.png'),
    green: require('../images/ams/sensors/sw/green.png'),
    orchid: require('../images/ams/sensors/sw/orchid.png'),
    pink: require('../images/ams/sensors/sw/pink.png'),
    red: require('../images/ams/sensors/sw/red.png'),
    yellow: require('../images/ams/sensors/sw/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_PRE]: {
    blue: require('../images/ams/sensors/pre/blue.png'),
    brown: require('../images/ams/sensors/pre/brown.png'),
    cornsilk: require('../images/ams/sensors/pre/cornsilk.png'),
    cyan: require('../images/ams/sensors/pre/cyan.png'),
    dark_blue: require('../images/ams/sensors/pre/dark_blue.png'),
    gold: require('../images/ams/sensors/pre/gold.png'),
    gray: require('../images/ams/sensors/pre/gray.png'),
    green: require('../images/ams/sensors/pre/green.png'),
    orchid: require('../images/ams/sensors/pre/orchid.png'),
    pink: require('../images/ams/sensors/pre/pink.png'),
    red: require('../images/ams/sensors/pre/red.png'),
    yellow: require('../images/ams/sensors/pre/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_NH3]: {
    blue: require('../images/ams/sensors/nh3/blue.png'),
    brown: require('../images/ams/sensors/nh3/brown.png'),
    cornsilk: require('../images/ams/sensors/nh3/cornsilk.png'),
    cyan: require('../images/ams/sensors/nh3/cyan.png'),
    dark_blue: require('../images/ams/sensors/nh3/dark_blue.png'),
    gold: require('../images/ams/sensors/nh3/gold.png'),
    gray: require('../images/ams/sensors/nh3/gray.png'),
    green: require('../images/ams/sensors/nh3/green.png'),
    orchid: require('../images/ams/sensors/nh3/orchid.png'),
    pink: require('../images/ams/sensors/nh3/pink.png'),
    red: require('../images/ams/sensors/nh3/red.png'),
    yellow: require('../images/ams/sensors/nh3/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_TEM]: {
    blue: require('../images/ams/sensors/tem/blue.png'),
    brown: require('../images/ams/sensors/tem/brown.png'),
    cornsilk: require('../images/ams/sensors/tem/cornsilk.png'),
    cyan: require('../images/ams/sensors/tem/cyan.png'),
    dark_blue: require('../images/ams/sensors/tem/dark_blue.png'),
    gold: require('../images/ams/sensors/tem/gold.png'),
    gray: require('../images/ams/sensors/tem/gray.png'),
    green: require('../images/ams/sensors/tem/green.png'),
    orchid: require('../images/ams/sensors/tem/orchid.png'),
    pink: require('../images/ams/sensors/tem/pink.png'),
    red: require('../images/ams/sensors/tem/red.png'),
    yellow: require('../images/ams/sensors/tem/yellow.png'),
  } as ColoredIcons,
  [iconName.AMS_SENTRO_8]: {
    blue: require('../images/ams/sensors/sentro_8/blue.png'),
    brown: require('../images/ams/sensors/sentro_8/brown.png'),
    cornsilk: require('../images/ams/sensors/sentro_8/cornsilk.png'),
    cyan: require('../images/ams/sensors/sentro_8/cyan.png'),
    dark_blue: require('../images/ams/sensors/sentro_8/dark_blue.png'),
    gold: require('../images/ams/sensors/sentro_8/gold.png'),
    gray: require('../images/ams/sensors/sentro_8/gray.png'),
    green: require('../images/ams/sensors/sentro_8/green.png'),
    orchid: require('../images/ams/sensors/sentro_8/orchid.png'),
    pink: require('../images/ams/sensors/sentro_8/pink.png'),
    red: require('../images/ams/sensors/sentro_8/red.png'),
    yellow: require('../images/ams/sensors/sentro_8/yellow.png'),
  } as ColoredIcons,
  [iconName.WIFI]: {
    blue: require('../images/wifi/wifi-blue.png'),
    red: require('../images/wifi/wifi-red.png'),
    gray: require('../images/wifi/wifi-gray.png'),
  } as ColoredIcons,
  [iconName.ALARM]: {
    blue: require('../images/alarms/alarm-blue.png'),
    red: require('../images/alarms/alarm-red.png'),
    gray: require('../images/alarms/alarm-gray.png'),
    yellow: require('../images/alarms/alarm-yellow.png'),
    brown: require('../images/alarms/alarm-brown.png'),
    default: require('../images/alarms/alarm-default.png'),
    cornsilk: require('../images/alarms/alarm-cornsilk.png'),
    cyan: require('../images/alarms/alarm-cyan.png'),
    green: require('../images/alarms/alarm-green.png'),
    dark_blue: require('../images/alarms/alarm-dark_blue.png'),
    gold: require('../images/alarms/alarm-gold.png'),
    orchid: require('../images/alarms/alarm-orchid.png'),
    pink: require('../images/alarms/alarm-pink.png'),
    orange: require('../images/alarms/alarm-orange.png'),
  } as ColoredIcons,
  [iconName.SAFEYE_NODE]: {
    default: require('../images/assets/asset-default.png'),
    blue: require('../images/assets/asset-blue.png'),
    brown: require('../images/assets/asset-brown.png'),
    cornsilk: require('../images/assets/asset-cornsilk.png'),
    cyan: require('../images/assets/asset-cyan.png'),
    green: require('../images/assets/asset-green.png'),
    dark_blue: require('../images/assets/asset-dark_blue.png'),
    gold: require('../images/assets/asset-gold.png'),
    gray: require('../images/assets/asset-gray.png'),
    orchid: require('../images/assets/asset-orchid.png'),
    pink: require('../images/assets/asset-pink.png'),
    red: require('../images/assets/asset-red.png'),
    yellow: require('../images/assets/asset-yellow.png'),
    orange: require('../images/assets/asset-orange.png'),
  } as ColoredIcons,
  [iconName.VISION]: {
    blue: require('../images/other/camera-blue.png'),
    yellow: require('../images/other/camera-yellow.png'),
    red: require('../images/other/camera-red.png'),
  } as ColoredIcons,
  [iconName.PERSON]: {
    blue: require('../images/other/user-blue.png'),
    yellow: require('../images/other/user-yellow.png'),
    red: require('../images/other/user-red.png'),
  } as ColoredIcons,
};

export const COLORED_LEAFLET_ICONS = mapValues(COLORED_ICONS, (i) =>
  mapValues(
    i,
    (iconUrl) =>
      new Icon({
        iconUrl,
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      })
  )
);

export const makeIconSmall = (icon: any) => {
  return cloneElement(icon, {sx: {fontSize: 16}});
};
