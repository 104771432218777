/* eslint-disable no-bitwise */
import CropSquareIcon from '@mui/icons-material/CropSquare';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import Lightbulb from '@mui/icons-material/Lightbulb';
import NetworkWifi1BarOutlinedIcon from '@mui/icons-material/NetworkWifi1BarOutlined';
import NetworkWifi2BarOutlinedIcon from '@mui/icons-material/NetworkWifi2BarOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import {useState} from 'react';

import {useConfiguration} from '../../../hooks/configuration';
import {useAppDispatch, useAppSelector} from '../../../hooks/redux';
import {
  amsSensorAddressMask,
  GasMonitoringNode,
  SentroType,
} from '../../../interfaces/GasMonitoringNode';
import reduxActions from '../../../redux/actions';
import {
  amsEventListHasAlarm,
  amsEventListHasAlert,
  amsNodeInAlarm,
  amsNodeInAlert,
  getAmsBatteryIconColor,
  getAMSBatteryIconType,
  getAmsBatteryPercent,
  getEmoduleUnitsLabel,
} from '../../../utils/ams';
import {AMSBatteryIcon} from '../../common/AMSBatteryIcon';

interface Props {
  item: GasMonitoringNode;
  items?: GasMonitoringNode[];
  onOpenItem?: (id: number, type: 'ams') => void;
  onOpenHistory?: (
    id: number,
    type: 'amsShortTerm' | 'amsLongTerm' | 'amsLocation'
  ) => void;
}

const AMSSensorTooltipContent = ({
  item,
  items,
  onOpenItem,
  onOpenHistory,
}: Props) => {
  const typeIdLabels = useAppSelector(({assets}) => assets.emoduleTypeIdLabel);
  const emoduleChannelLabel = useAppSelector(
    ({assets}) => assets.sentro8ChannelLabel
  );
  const reduxAppDispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const amsNodeLowBattery = useConfiguration('ams', 'ams_node_low_battery');
  const ventilationFailure = useConfiguration('ams', 'ventilation_failure');
  const historyAvailable = item?.emodules.length > 0;

  const loadAssets = () => {
    reduxAppDispatch(reduxActions.assets.fetchAmsEmodules);
    reduxAppDispatch(reduxActions.assets.fetchGasMonitorNodes);
  };

  const generate_ICON_AMS_LOW_BATTERY = (item: GasMonitoringNode) => {
    const externalVoltage = item?.external_voltage;
    const batteryVoltage = item?.battery_voltage;
    const isOnExternalCharger = item?.is_on_external_charger;

    if (!externalVoltage && !batteryVoltage) {
      return <></>;
    }

    const tooltipContent =
      !isOnExternalCharger || !externalVoltage || externalVoltage < 2
        ? getAmsBatteryPercent(batteryVoltage)
        : `${externalVoltage} v`;
    const iconColor = getAmsBatteryIconColor({
      batteryVoltage,
      externalVoltage,
      isOnExternalCharger,
      amsNodeLowBatteryValue: amsNodeLowBattery?.value,
      isLowBatteryEvent: item?.events.e_405,
      isLowExternalChargeEvent: item?.events.e_409,
    });
    const iconType = getAMSBatteryIconType({
      batteryVoltage,
      externalVoltage,
      isOnExternalCharger,
    });
    const iconColorProp: {color?: 'error'} = iconColor
      ? {color: iconColor}
      : {};

    return (
      <Tooltip title={tooltipContent}>
        {iconType === 'power' ? (
          <PowerSettingsNewOutlinedIcon {...iconColorProp} />
        ) : (
          <div>
            <AMSBatteryIcon iconType={iconType} {...iconColorProp} />
          </div>
        )}
      </Tooltip>
    );
  };
  const generate_ICON_Events = (item: GasMonitoringNode) => {
    if (item.disable_event) {
      return (
        <Tooltip title="Events are Disabled">
          <DoDisturbIcon />
        </Tooltip>
      );
    }
  };
  const generate_ICON_Alert_Alarm = (item: GasMonitoringNode) => {
    if (item.disable_event === 0 && amsNodeInAlarm(item)) {
      return (
        <Tooltip title="Alarm">
          <WarningIcon color="error" />
        </Tooltip>
      );
    } else if (item.disable_event === 0 && amsNodeInAlert(item)) {
      return (
        <Tooltip title="Alert">
          <WarningIcon color="warning" />
        </Tooltip>
      );
    } else {
      return <></>;
    }
  };
  const generate_ICON_Heartbeat = (item: GasMonitoringNode) => {
    if (item.events.e_407) {
      return (
        <Tooltip title="AMS Sensors many ENV messages">
          <ForumOutlinedIcon />
        </Tooltip>
      );
    } else if (item.events.e_404) {
      return (
        <Tooltip title="AMS Sensors no ENV messages">
          <NetworkWifi1BarOutlinedIcon color="error" />
        </Tooltip>
      );
    } else if (item.events.e_406) {
      return (
        <Tooltip title="AMS Sensors few ENV messages">
          <NetworkWifi2BarOutlinedIcon color="warning" />
        </Tooltip>
      );
    }
  };
  const generate_ICON_AVA = (item: GasMonitoringNode) => {
    if (item.ava) {
      const avaStatusAck = item?.ava_status_ack;
      const avaStatus = item?.ava_status;

      const AvaIcon =
        avaStatus === avaStatusAck
          ? FiberManualRecordIcon
          : FiberManualRecordOutlinedIcon;

      if (avaStatus === 402) {
        return (
          <Tooltip title="Alarm">
            <AvaIcon color="error" />
          </Tooltip>
        );
      }

      if (avaStatus === 403) {
        return (
          <Tooltip title="Alert">
            <AvaIcon color="warning" />
          </Tooltip>
        );
      }

      return (
        <Tooltip title="Ok">
          <AvaIcon color="success" />
        </Tooltip>
      );
    }
  };
  const generate_ICON_Ventilation = (item: GasMonitoringNode) => {
    if (
      ventilationFailure?.value &&
      !item?.intrinsically_safe &&
      item?.ventilation_status
    ) {
      return item?.ventilation_status_ack ? (
        <Tooltip title="ACK">
          <SquareRoundedIcon color="error" />
        </Tooltip>
      ) : (
        <Tooltip title="SENT">
          <CropSquareIcon color="error" />
        </Tooltip>
      );
    }
  };

  const generate_ICON_New_Sensor_Detected = (item: GasMonitoringNode) => {
    if (item.ack === '0') {
      return (
        <Tooltip title="New sensor detected">
          <Lightbulb sx={{color: 'black', fontSize: 25}} />
        </Tooltip>
      );
    }
  };

  const generate_Sentro_Description = (item: GasMonitoringNode) => {
    if (item.sentro_type === SentroType.SENTRO_1) {
      return (
        <>
          <Box>Name: {item?.name ?? '-'}</Box>
          <Box>AMS Sensor SN: {item.sensor_serial_number}</Box>
          <Box>
            Network ID:{' '}
            {item?.commtrac_external_id
              ? item?.commtrac_external_id & amsSensorAddressMask
              : '--'}
          </Box>
          <Box>
            Type: {typeIdLabels ? typeIdLabels[item?.type_id ?? 255] : '-'}
          </Box>
          <Box>Value: {item?.value}</Box>
          <Box>Calibration Date: {item.callibration_date ?? '-'}</Box>
          <Box display="flex" alignItems="center">
            {item.events.e_408 && (
              <Tooltip title="e-Module Calibration Date Expired">
                <TimerOutlinedIcon color="error" />
              </Tooltip>
            )}
            {item.events.e_410 && (
              <Tooltip title="Unregistered e-Module">
                <TheatersOutlinedIcon />
              </Tooltip>
            )}
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Box>Name: {item?.name ?? '-'}</Box>
          <Box>
            Network ID:{' '}
            {item?.commtrac_external_id
              ? item?.commtrac_external_id & amsSensorAddressMask
              : '--'}
          </Box>
          {item.emodules.map((node, index) => (
            <Box
              sx={{
                color: amsEventListHasAlarm(item.channel_events?.[node.id])
                  ? 'error.main'
                  : amsEventListHasAlert(item.channel_events?.[node.id])
                    ? 'warning.main'
                    : '',
              }}
              key={`${node.id}-${index}`}
            >
              Ch{' '}
              {emoduleChannelLabel && node.channel
                ? emoduleChannelLabel[node.channel]
                : ''}
              : {typeIdLabels ? typeIdLabels[node?.type ?? 255] : '-'} -{' '}
              {node?.value} {getEmoduleUnitsLabel(node.type, node.units)} (
              {node?.date})
              {item.channel_events?.[node.id]?.e_408 ? (
                <Tooltip title="e-Module Calibration Date Expired">
                  <TimerOutlinedIcon
                    sx={{fontSize: '15px', margin: '0 0 -3px 3px'}}
                    color="error"
                  />
                </Tooltip>
              ) : null}
              {item.channel_events?.[node.id]?.e_410 ? (
                <TheatersOutlinedIcon
                  sx={{fontSize: '15px', margin: '0 0 -3px 3px'}}
                  color="error"
                />
              ) : null}
            </Box>
          ))}
        </>
      );
    }
  };

  if ((items?.length ?? 0) > 1) {
    return (
      <>
        {items?.map((it, no) => {
          const {type_id} = it;

          return (
            <Box key={it.id}>
              {no + 1}. SN: {it.sensor_serial_number}, Type:{' '}
              {typeIdLabels ? typeIdLabels[type_id ?? 255] : '-'}
            </Box>
          );
        })}
      </>
    );
  } else if ((items?.length ?? 0) < 2) {
    return (
      <Box display="flex" flexDirection="column" gap={0.5}>
        {generate_Sentro_Description(item)}
        <Box display="flex" alignItems="center">
          {generate_ICON_New_Sensor_Detected(item)}
          {generate_ICON_AMS_LOW_BATTERY(item)}
          {generate_ICON_Events(item)}
          {generate_ICON_Alert_Alarm(item)}
          {generate_ICON_Heartbeat(item)}
          {generate_ICON_AVA(item)}
          {generate_ICON_Ventilation(item)}
        </Box>
        <Box display="flex" my={1}>
          {historyAvailable ? (
            <Box position="relative">
              <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
                History
              </Button>
              {item.sentro_type === SentroType.SENTRO_1 ? (
                <Menu
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onBackdropClick={() => setAnchorEl(null)}
                >
                  <MenuItem
                    onClick={() => {
                      loadAssets();
                      onOpenHistory?.(item?.emodules?.[0].id, 'amsShortTerm');
                      setAnchorEl(null);
                    }}
                  >
                    Short Term History Report
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      loadAssets();
                      onOpenHistory?.(item?.emodules?.[0].id, 'amsLongTerm');
                      setAnchorEl(null);
                    }}
                  >
                    Long Term History Report
                  </MenuItem>
                </Menu>
              ) : (
                <>
                  <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onBackdropClick={() => setAnchorEl(null)}
                  >
                    <MenuItem>
                      <Accordion
                        elevation={0}
                        disableGutters
                        sx={{
                          margin: 0,
                          backgroundColor: 'transparent',
                        }}
                      >
                        <AccordionSummary
                          sx={{
                            margin: '-12px 0',
                            padding: 0,
                            minHeight: 0,
                          }}
                        >
                          Short Term History Report
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {item?.emodules?.map((emodule, i) => (
                            <MenuItem
                              onClick={() => {
                                loadAssets();
                                onOpenHistory?.(emodule?.id, 'amsShortTerm');
                                setAnchorEl(null);
                              }}
                              key={i}
                            >
                              Channel{' '}
                              {emoduleChannelLabel && emodule.channel
                                ? emoduleChannelLabel[emodule.channel]
                                : ''}
                            </MenuItem>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </MenuItem>
                    <MenuItem>
                      <Accordion
                        elevation={0}
                        disableGutters
                        sx={{
                          backgroundColor: 'transparent',
                        }}
                      >
                        <AccordionSummary
                          sx={{
                            margin: '-12px 0',
                            padding: 0,
                            minHeight: 0,
                          }}
                          classes={{
                            content: 'custom-accordion',
                          }}
                        >
                          Long Term History Report
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            padding: 0,
                            margin: 0,
                            marginTop: '.5rem',
                          }}
                        >
                          {item?.emodules?.map((emodule, i) => (
                            <MenuItem
                              onClick={() => {
                                loadAssets();
                                onOpenHistory?.(emodule?.id, 'amsLongTerm');
                                setAnchorEl(null);
                              }}
                              key={i}
                            >
                              Channel{' '}
                              {emoduleChannelLabel && emodule.channel
                                ? emoduleChannelLabel[emodule.channel]
                                : ''}
                            </MenuItem>
                          ))}
                        </AccordionDetails>
                      </Accordion>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </Box>
          ) : null}
          <IconButton
            sx={{
              marginLeft: 'auto',
            }}
            size="small"
            onClick={() => onOpenItem?.(item.id, 'ams')}
          >
            <EditIcon sx={{color: (theme) => theme.palette.primary.dark}} />
          </IconButton>
        </Box>
      </Box>
    );
  }
  return null;
};

export default AMSSensorTooltipContent;
