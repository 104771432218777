import {
  Alarm,
  AlarmOutlined,
  CheckOutlined,
  DisabledByDefault,
} from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WifiIcon from '@mui/icons-material/Wifi';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import {useEffect, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';

import {useConfiguration} from '../../../hooks/configuration';
import {useAppSelector} from '../../../hooks/redux';
import usePrevious from '../../../hooks/usePrevious';
import {
  AlarmModuleNode,
  AlarmModuleNodeListResponse,
} from '../../../interfaces/AlarmModuleNode';
import {AlarmListQuery} from '../../../interfaces/AlarmType';
import reduxSelectors from '../../../redux/selectors';
import {
  OpenedEntityMode,
  OpenedEntityType,
} from '../../../utils/connect-view-panel';
import AlarmAcknowledgeButton from '../../alarm-nodes/AlarmAcknowledgeButton';
import AlarmItemEditButton from '../../alarm-nodes/AlarmItemEditButton';
import AlarmItemUpsertButton from '../../alarm-nodes/AlarmItemUpsertButton';
import AlarmModuleImportExportButton from '../../alarm-nodes/AlarmLogImportExportButton';
import AlarmUpdateStatusButton from '../../alarm-nodes/AlarmUpdateStatusButton';
import AlarmWarningAllShortCodeButton from '../../alarm-nodes/AlarmWarningAllShortCodeButton';
import AlarmModulesPurgeButton from '../../alarm-nodes/buttons/AlarmModulesPurgeButton';
import AccessControl from '../../common/AccessControl';
import {AutoRefreshSettingsSelect} from '../../common/AutoRefreshSettingsSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {Map, MapLatLangCoordinates, MapLayerId} from '../../common/Map';
import {ResizableColumns} from '../../common/ResizableColumns';
import {ZoneSelect} from '../../selectors/ZoneSelect';
import {DashboardHistoryReportType} from '../DashboardPanelItem';

interface Props {
  type?: 'separate_panel' | 'conncet_live';
  value?: AlarmLogReportData;
  fetchedData?: AlarmModuleNodeListResponse;
  loading?: boolean;
  onChange?: (value?: AlarmLogReportData) => void;
  locationCoordinates?: MapLatLangCoordinates;
  onOpenHistory?: (
    id: number | string,
    type: DashboardHistoryReportType
  ) => void;
  onOpenItem?: (
    // item: AlarmLogNode
    id: number,
    entity: OpenedEntityType,
    mode?: OpenedEntityMode
  ) => void;
  onRefresh?: () => void;
  onChangeLocationCoordinates?: (value?: MapLatLangCoordinates) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'no_heartbeat',
  'type',
  'reason',
  'note',
  'name',
  'status',
  'zone_id',
  'mac_address',
  'date',
];

export interface AlarmLogReportData {
  mapLayers: MapLayerId[];
  mapLevel: number | null;
  params: Partial<AlarmListQuery>;
  selectedIds: number[] | null;
  shownFields: string[];
  grid: {
    pageSize: number;
    page: number;
  };
  enableAlarm: {
    open: boolean;
    message_id?: number;
    commtrac_external_id?: string;
  };
}

export const getAlarmLogReportData = (): AlarmLogReportData => {
  return {
    mapLayers: ['street', 'mine', 'alarms'],
    mapLevel: null,
    params: {
      status: 'active',
      node_type: [],
      section_ids: [],
      limit: 2000,
      page: 0,
    },
    shownFields: DEFAULT_SHOWN_FIELDS,
    selectedIds: null,
    grid: {
      page: 0,
      pageSize: 25,
    },
    enableAlarm: {
      open: false,
    },
  };
};

const AlarmLogReport = ({
  type = 'conncet_live',
  value,
  fetchedData,
  loading,
  locationCoordinates,
  onChange,
  onOpenItem,
  onOpenHistory,
  onRefresh,
  onChangeLocationCoordinates,
}: Props) => {
  const config = useMemo(() => value ?? getAlarmLogReportData(), [value]);
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const activationReason = useAppSelector(
    ({assets}) => assets.activationReason
  );
  const isCompactMode = useAppSelector(reduxSelectors.app.getIsCompactMode);

  /*************/
  /* data grid */
  /*************/
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = useMemo(() => fetchedData?.items ?? [], [fetchedData]);
  const columns: DataGridColumn<AlarmModuleNode>[] = [
    {
      field: 'select',
      type: 'select',
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(row.id)}
          onChange={() => toggleSelectItem(row.id)}
        />
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'actions',
      headerName: 'Edit',
      renderHeader: () => (
        <Tooltip title="More">
          <MoreHorizIcon />
        </Tooltip>
      ),
      type: 'actions',
      sxHeader: {textAlign: 'center', p: 0.5},
      sxCell: {textAlign: 'center', p: 0.5},
      renderCell: ({row}) => {
        if (Number(row.acknowledged) !== 1) {
          return (
            <AccessControl
              permissions={['patch::/alarm-module/:id(\\d+)/acknowledge']}
            >
              <AlarmAcknowledgeButton
                locationCoordinates={locationCoordinates}
                item={row}
                component={IconButton}
                componentProps={{
                  color: 'warning',
                }}
                onSubmitted={(item) => {
                  onRefresh?.();
                  onOpenItem?.(item.id, 'alarm', 'view');
                }}
              >
                <WarningAmberIcon />
              </AlarmAcknowledgeButton>
            </AccessControl>
          );
        } else {
          return (
            <AlarmItemEditButton
              item={row}
              onOpenItem={onOpenItem}
              onOpenHistory={onOpenHistory}
              component={IconButton}
              componentProps={{
                color: 'primary',
              }}
            >
              <MoreHorizIcon />
            </AlarmItemEditButton>
          );
        }
      },
    },
    {
      field: 'type',
      headerName: 'Alarm',
      sortable: true,
      sxHeader: {minWidth: 60},
      renderHeader: () => {
        return (
          <Tooltip title="Alarm">
            <AlarmOutlined />
          </Tooltip>
        );
      },
      renderCell: ({row}) => {
        if (row.alarm) {
          const tooltipContent = `
            <span>Alarm</span><br />
            <span>Date: ${row.alarm_at}</span><br />
            <span>Reason: ${activationReason?.find((it) => it.key === row.alarm_reason)?.label ?? ''}</span><br />
            <span>Reason Note: ${row.alarm_reason_note ?? ''}</span>
          `;
          return (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{__html: tooltipContent}} />}
              arrow
            >
              <AlarmOutlined color="error" />
            </Tooltip>
          );
        } else if (row.warning) {
          const tooltipContent = `
            <span>Warning</span><br />
            <span>Date: ${row.warning_at}</span><br />
            <span>Reason: ${activationReason?.find((it) => it.key === row.warning_reason)?.label ?? ''}</span><br />
            <span>Reason Note: ${row.warning_reason_note ?? ''}</span>
          `;
          return (
            <Tooltip
              title={<div dangerouslySetInnerHTML={{__html: tooltipContent}} />}
              arrow
            >
              <AlarmOutlined color="warning" />
            </Tooltip>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'no_heartbeat',
      headerName: 'No Heartbeat',
      sxHeader: {minWidth: 60},
      renderHeader: () => (
        <Tooltip title="No Heartbeat">
          <WifiIcon />
        </Tooltip>
      ),
      sortable: true,
      hideable: false,
      renderCell: ({row}) => {
        if (row.e_701 === true) {
          return (
            <Tooltip title="No Heartbeat">
              <SignalWifi0BarIcon color="error" />
            </Tooltip>
          );
        } else {
          return '';
        }
      },
    },
    {
      field: 'reason',
      headerName: 'Reason',
      sxCell: {whiteSpace: 'nowrap'},
      renderCell: ({row}) => {
        if (row.alarm) {
          return (
            <>
              {activationReason?.find((it) => it.key === row.alarm_reason)
                ?.label ?? ''}
            </>
          );
        } else if (row.warning) {
          return (
            <>
              {activationReason?.find((it) => it.key === row.warning_reason)
                ?.label ?? ''}
            </>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'note',
      headerName: 'Note',
      sxCell: {width: '100%', minWidth: 150, whiteSpace: 'normal !important'},

      renderCell: ({row}) => {
        if (row.alarm) {
          return <>{row.alarm_reason_note}</>;
        } else if (row.warning) {
          return <>{row.warning_reason_note}</>;
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      sxCell: {width: '100%', minWidth: 200},
      sortable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      renderCell: ({row}) => {
        return <Box textTransform="capitalize">{row.status}</Box>;
      },
    },
    {
      field: 'zone_id',
      headerName: 'Section',
      sortable: true,
      renderCell: ({row}) => {
        return (
          <Box whiteSpace="nowrap">
            {assets?.zones?.find((it) => it.id === row.zone_id)?.name}
          </Box>
        );
      },
    },
    {
      field: 'mac_address',
      headerName: 'Mac Address',
      sortable: true,
      renderCell: ({row}) => {
        return row.mac_address ?? '';
      },
    },
    {
      field: 'longitude',
      headerName: 'Lon',
      sortable: true,
      valueGetter: ({row}) => row.longitude,
    },
    {
      field: 'latitude',
      headerName: 'Lat',
      sortable: true,
      valueGetter: ({row}) => row.latitude,
    },
    {
      field: 'date',
      headerName: 'Timestamp',
      sxCell: {whiteSpace: 'nowrap'},
      sortable: true,
      renderCell: ({row}) => {
        return row.date ? dayjs(row.date).format('YYYY-MM-DD HH:mm:ss') : '';
      },
    },
    {
      field: 'analog_1',
      headerName: 'Analog 1',
      sortable: true,
      valueGetter: ({row}) => row.analog_1,
    },
    {
      field: 'analog_2',
      headerName: 'Analog 2',
      sortable: true,
      valueGetter: ({row}) => row.analog_2,
    },
    {
      field: 'activated_at',
      headerName: 'Activated at',
      sortable: true,
      valueGetter: ({row}) =>
        row.activated_at
          ? dayjs(row.activated_at).format('YYYY-MM-DD HH:mm:ss')
          : '',
    },
    {
      field: 'deactivated_at',
      headerName: 'Deactivated at',
      sortable: true,
      valueGetter: ({row}) =>
        row.deactivated_at
          ? dayjs(row.deactivated_at).format('YYYY-MM-DD HH:mm:ss')
          : '',
    },
    {
      field: 'created_at',
      headerName: 'Created at',
      sortable: true,
      valueGetter: ({row}) =>
        row.created_at
          ? dayjs(row.created_at).format('YYYY-MM-DD HH:mm:ss')
          : '',
    },
    {
      field: 'updated_at',
      headerName: 'Updated at',
      sortable: true,
      valueGetter: ({row}) =>
        row.updated_at
          ? dayjs(row.updated_at).format('YYYY-MM-DD HH:mm:ss')
          : '',
    },
  ];

  const shownFields = useMemo(() => {
    return config.shownFields;
  }, [config]);

  const handleChangeShownFields = (fields: string[]) => {
    onChange?.(
      update(config, {
        shownFields: {
          $set: fields,
        },
      })
    );
  };

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = config.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i) => config.selectedIds?.includes(i.id)),
    [rows, config.selectedIds]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: number) => {
    if (config.selectedIds?.includes(id)) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: config.selectedIds.filter((i) => i !== id),
          },
        })
      );
    } else {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: [...(config.selectedIds ?? []), id],
          },
        })
      );
    }
  };

  const selectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: rows?.map((i) => i.id) ?? [],
        },
      })
    );
  };

  const unselectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: [],
        },
      })
    );
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (
      config.selectedIds &&
      config.selectedIds.length !== selectedRows.length
    ) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: selectedRows.map((i) => i.id),
          },
        })
      );
    }
  }, [config.selectedIds, selectedRows]);

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  /************/
  /* map data */
  /************/
  const mapData = useMemo(() => {
    if (fetchedData?.items && config.selectedIds?.length) {
      return fetchedData?.items.filter((i) =>
        config.selectedIds?.includes(i.id)
      );
    }
    return [];
  }, [fetchedData, config.selectedIds, selectedItems]);

  /********************/
  /* refresh interval */
  /********************/
  const refreshPeriod = useConfiguration('alarm', 'alarm_autorefresh_rate');

  return (
    <Box height="100%" width="100%">
      {type === 'conncet_live' ? (
        <ResizableColumns
          left={
            <>
              <Box
                overflow="auto"
                height={isCompactMode ? 56 : 70}
                minHeight={isCompactMode ? 56 : 70}
                sx={{
                  overflowY: 'hidden',
                  width: 'calc(100% - 24px)',
                }}
                display="flex"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  p={isCompactMode ? 1 : 2}
                  minWidth={370}
                  gap={2}
                >
                  <AlarmUpdateStatusButton
                    disabled={config.params.status === 'inactive'}
                    type="enable"
                    component={Button}
                    componentProps={{
                      sx: {
                        whiteSpace: 'nowrap',
                      },
                      color: 'primary',
                      variant: 'outlined',
                      startIcon: <CheckOutlined />,
                    }}
                    preSelected={selectedItems}
                    messageId={value?.enableAlarm.message_id}
                    externalId={value?.enableAlarm?.commtrac_external_id}
                    forceOpen={value?.enableAlarm?.open}
                    onClose={() => {
                      onChange?.(
                        update(value, {enableAlarm: {open: {$set: false}}})
                      );
                    }}
                    onSubmitted={() => {
                      onChange?.(
                        update(value, {enableAlarm: {open: {$set: false}}})
                      );
                      onRefresh?.();
                    }}
                  >
                    Enable Alarm
                  </AlarmUpdateStatusButton>
                  <AlarmUpdateStatusButton
                    disabled={config.params.status === 'inactive'}
                    type="disable"
                    component={Button}
                    componentProps={{
                      color: 'primary',
                      sx: {
                        whiteSpace: 'nowrap',
                      },
                      variant: 'outlined',
                      startIcon: <DisabledByDefault />,
                    }}
                    preSelected={selectedItems}
                    onSubmitted={() => onRefresh?.()}
                  >
                    Disable Alarm
                  </AlarmUpdateStatusButton>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  p={isCompactMode ? 1 : 2}
                  minWidth="fit-content"
                  gap={2}
                  marginLeft="auto"
                >
                  <AlarmWarningAllShortCodeButton
                    disabled={config.params.status === 'inactive'}
                    type="warn"
                    component={Button}
                    componentProps={{
                      sx: {
                        whiteSpace: 'nowrap',
                      },
                      color: 'primary',
                      variant: 'outlined',
                    }}
                    onClose={() => {
                      onChange?.(
                        update(value, {enableAlarm: {open: {$set: false}}})
                      );
                    }}
                    onSubmitted={() => {
                      onChange?.(
                        update(value, {enableAlarm: {open: {$set: false}}})
                      );
                      onRefresh?.();
                    }}
                  >
                    Warn All
                  </AlarmWarningAllShortCodeButton>
                  <AlarmWarningAllShortCodeButton
                    disabled={config.params.status === 'inactive'}
                    type="evacuate"
                    component={Button}
                    componentProps={{
                      sx: {
                        whiteSpace: 'nowrap',
                      },
                      color: 'primary',
                      variant: 'outlined',
                    }}
                    onClose={() => {
                      onChange?.(
                        update(value, {enableAlarm: {open: {$set: false}}})
                      );
                    }}
                    onSubmitted={() => {
                      onChange?.(
                        update(value, {enableAlarm: {open: {$set: false}}})
                      );
                      onRefresh?.();
                    }}
                  >
                    Evacuate All
                  </AlarmWarningAllShortCodeButton>
                </Box>
              </Box>
              <Map
                panel="alarm_log_report"
                alarmNodes={mapData}
                selectedMapLayers={config.mapLayers}
                selectedLevel={config.mapLevel}
                height="calc(100% - 70px)"
                minWidth={200}
                availableMapLayers={[
                  'employees',
                  'assets',
                  'street',
                  'mine',
                  'nodes',
                  'alarms',
                ]}
                onSelectMapLayers={(v) => {
                  onChange?.(
                    update(config, {
                      mapLayers: {
                        $set: v,
                      },
                    })
                  );
                }}
                onSelectLevel={(v) => {
                  onChange?.(
                    update(config, {
                      mapLevel: {
                        $set: v,
                      },
                    })
                  );
                }}
                onOpenItem={(e) => {
                  onOpenItem?.(e, 'alarm', 'view');
                }}
                onOpenHistory={onOpenHistory}
                onGetClickCoordinates={onChangeLocationCoordinates}
              />
            </>
          }
        >
          <Box display="flex" flexDirection="column" height="100%">
            <Box
              overflow="auto"
              height={isCompactMode ? 56 : 70}
              minHeight={isCompactMode ? 56 : 70}
              sx={{
                overflowY: 'hidden',
              }}
            >
              <Box
                p={isCompactMode ? 1 : 2}
                minWidth={550}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Box flexGrow={1} display="flex" height="100%">
                  <ZoneSelect
                    value={config.params.section_ids?.[0]}
                    size="small"
                    nullLabel="All Sections"
                    fullWidth
                    onChange={(v) => {
                      const sectionIds = v ? [+v] : [];
                      onChange?.(
                        update(config, {
                          params: {
                            section_ids: {
                              $set: sectionIds,
                            },
                          },
                        })
                      );
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  height="100%"
                  sx={{
                    marginLeft: 'auto',
                  }}
                >
                  <ButtonGroup>
                    <AccessControl permissions={['post::/alarm-module']}>
                      <AlarmItemUpsertButton
                        locationCoordinates={locationCoordinates}
                        component={Button}
                        componentProps={{
                          color: 'primary',
                          size: 'small',
                          startIcon: <AddIcon />,
                        }}
                        onSubmitted={() => {
                          onRefresh?.();
                          onChangeLocationCoordinates?.(undefined);
                        }}
                        onClose={() => {
                          onChangeLocationCoordinates?.(undefined);
                        }}
                      >
                        <Alarm />
                      </AlarmItemUpsertButton>
                    </AccessControl>
                    <AlarmModuleImportExportButton
                      component={Button}
                      componentProps={{color: 'primary'}}
                      onSubmitted={() => onRefresh?.()}
                    >
                      <ImportExportIcon />
                    </AlarmModuleImportExportButton>
                    <Button onClick={() => onRefresh?.()}>
                      <Tooltip title="Refresh">
                        <RefreshIcon />
                      </Tooltip>
                    </Button>

                    {refreshPeriod ? (
                      <AutoRefreshSettingsSelect
                        refreshPeriod={refreshPeriod}
                      />
                    ) : null}

                    <Button onClick={() => dataGridRef.current?.printTable()}>
                      <PrintIcon />
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
              {/* </Box> */}
            </Box>
            <DataGrid
              ref={dataGridRef}
              rows={rows}
              columns={columns}
              size="small"
              pagination
              page={config.grid.page}
              pageSize={config.grid.pageSize}
              shownFields={shownFields}
              loading={loading}
              sxFooter={{
                bgcolor: (theme) =>
                  theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
              }}
              footerStart={
                selectedItems.length ? (
                  <Box display="flex" alignItems="center" gap={3} px={1}>
                    <Box
                      display="flex"
                      gap={0.5}
                      alignItems="center"
                      height="100%"
                      whiteSpace="nowrap"
                    >
                      {selectedItems.length} selected
                    </Box>
                    <AccessControl permissions={['post::/purge']}>
                      <Box display="flex" height={40}>
                        <AlarmModulesPurgeButton
                          alarmModuleIds={selectedRows.map((i) => i.id)}
                          componentProps={{
                            size: 'small',
                            variant: 'outlined',
                          }}
                          onDone={onRefresh}
                        >
                          <RemoveCircleOutlineIcon
                            fontSize="small"
                            sx={{mr: 1}}
                          />{' '}
                          Purge
                        </AlarmModulesPurgeButton>
                      </Box>
                    </AccessControl>
                  </Box>
                ) : null
              }
              onShownFieldsChange={handleChangeShownFields}
              onPageChange={(v) => {
                onChange?.(
                  update(config, {
                    grid: {
                      page: {
                        $set: v,
                      },
                    },
                  })
                );
              }}
              onPageSizeChange={(v) => {
                onChange?.(
                  update(config, {
                    grid: {
                      pageSize: {
                        $set: v,
                      },
                    },
                  })
                );
              }}
            />
          </Box>
        </ResizableColumns>
      ) : (
        <Box display="flex" flexDirection="column" height="100%">
          <Box
            p={2}
            sx={{
              overflowX: 'auto',
              overflowY: 'hidden',
            }}
          >
            <Box minWidth={800}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                height={40}
                gap={1}
              >
                <Box flexGrow={1} display="flex" height="100%">
                  <ZoneSelect
                    value={config.params.section_ids?.[0]}
                    size="small"
                    nullLabel="All Sections"
                    fullWidth
                    onChange={(v) => {
                      const sectionIds = v ? [+v] : [];
                      onChange?.(
                        update(config, {
                          params: {
                            section_ids: {
                              $set: sectionIds,
                            },
                          },
                        })
                      );
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  height="100%"
                  sx={{
                    marginLeft: 'auto',
                  }}
                >
                  <ButtonGroup>
                    <AlarmItemUpsertButton
                      locationCoordinates={locationCoordinates}
                      component={Button}
                      componentProps={{
                        color: 'primary',
                        size: 'small',
                        startIcon: <AddIcon />,
                      }}
                      onSubmitted={() => {
                        onRefresh?.();
                        onChangeLocationCoordinates?.(undefined);
                      }}
                      onClose={() => {
                        onChangeLocationCoordinates?.(undefined);
                      }}
                    >
                      <Alarm />
                    </AlarmItemUpsertButton>
                    <AlarmModuleImportExportButton
                      component={Button}
                      componentProps={{color: 'primary'}}
                      onSubmitted={() => onRefresh?.()}
                    >
                      <ImportExportIcon />
                    </AlarmModuleImportExportButton>
                    <Button onClick={() => onRefresh?.()}>
                      <RefreshIcon />
                    </Button>

                    {refreshPeriod ? (
                      <AutoRefreshSettingsSelect
                        refreshPeriod={refreshPeriod}
                      />
                    ) : null}

                    <Button onClick={() => dataGridRef.current?.printTable()}>
                      <PrintIcon />
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>
          </Box>
          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            size="small"
            pagination
            page={config.grid.page}
            pageSize={config.grid.pageSize}
            shownFields={shownFields}
            loading={loading}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            sx={{
              td: {
                whiteSpace: 'nowrap',
              },
            }}
            footerStart={
              selectedItems.length ? (
                <Box display="flex" alignItems="center" gap={3} px={1}>
                  <Box
                    display="flex"
                    gap={0.5}
                    alignItems="center"
                    height="100%"
                    whiteSpace="nowrap"
                  >
                    {selectedItems.length} selected
                  </Box>
                </Box>
              ) : null
            }
            onShownFieldsChange={handleChangeShownFields}
            onPageChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    page: {
                      $set: v,
                    },
                  },
                })
              );
            }}
            onPageSizeChange={(v) => {
              onChange?.(
                update(config, {
                  grid: {
                    pageSize: {
                      $set: v,
                    },
                  },
                })
              );
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default AlarmLogReport;
