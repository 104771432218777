/* eslint-disable complexity */
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tab,
  TextField,
} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useConfiguration} from '../../hooks/configuration';
import {useAppSelector} from '../../hooks/redux';
import {AlarmModuleNode} from '../../interfaces/AlarmModuleNode';
import reduxSelectors from '../../redux/selectors';
import AccessControl from '../common/AccessControl';
import {getHasPermission} from '../common/AccessControl/utils';
import StatusSelect from '../selectors/StatusSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';
import AlarmItemActionsButton from './AlarmItemActionsButton';
import AlarmItemHistoryButton from './AlarmItemHistoryButton';

interface Props {
  pk: number;
  item?: AlarmModuleNode;
  prefetch?: boolean;
  tab?: TabValue;
  onCancel?: () => void;
  onEdit?: () => void;
  onAck?: () => void;
  onPurged?: () => void;
  onFetched?: (item: AlarmModuleNode) => void;
  onSubmitted?: () => void;
  onOpenHistory?: (item: AlarmModuleNode, type: 'alarm' | 'alarm_log') => void;
}
type TabValue = 'general' | 'local_button_press';
interface TabItem {
  value: TabValue;
  label: string;
  visible: boolean;
}

const AlarmItemView = ({
  pk,
  item,
  prefetch,
  tab: initialTab = 'general',
  onCancel,
  onEdit,
  onAck,
  onPurged,
  onFetched,
  onOpenHistory,
  onSubmitted,
}: // onSubmitted,
Props) => {
  /*********/
  /* fetch */
  /*********/
  const controlRoomMonitored = useConfiguration(
    'global',
    'control_room_is_monitored'
  );
  const isLocalButtonPressActionDisabled = useMemo(() => {
    return controlRoomMonitored?.value
      ? +controlRoomMonitored?.value === 0
      : false;
  }, [controlRoomMonitored]);
  const [fetchedData, setFetchedData] = useState<AlarmModuleNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const me = useAppSelector(({app}) => app.me);
  const localButtonPressAction = assets.localButtonPressAction;
  const {zones, alarm_groups} = assets;

  const tabs: TabItem[] = [
    {
      value: 'general',
      label: 'General',
      visible: true,
    },
    {
      value: 'local_button_press',
      label: 'Local Button Press',
      visible: true,
    },
  ];
  const visibleTabs = tabs.filter((i) => i.visible);
  const [activeTab, setActiveTab] = useState(initialTab);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<AlarmModuleNode>(
        `${apiBaseUrl}/alarm-module/${pk}`
      );
      setFetchedData(resp.data);
      onFetched?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
      const iconEle = document.getElementsByClassName(
        'alarm_action_icon'
      )[0] as HTMLElement;
      iconEle.click();
    }
  }, [pk, prefetch]);
  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  return (
    <Box display="flex" flexDirection="column" position="relative" gap={3}>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}

      <TabContext value={activeTab}>
        <TabList variant="fullWidth">
          {visibleTabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              disabled={
                tab.value === 'local_button_press' &&
                isLocalButtonPressActionDisabled
              }
              onClick={() => setActiveTab(tab.value)}
            />
          ))}
        </TabList>
        <TabPanel value="general" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={fetchedData?.name ?? ''}
              label="Name"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.mac_address ?? ''}
              label="Mac Address"
              size="small"
              disabled
              fullWidth
            />

            <ZoneSelect
              value={fetchedData?.zone_id}
              label="Assigned Section"
              size="small"
              disabled
              fullWidth
            />

            <StatusSelect
              value={fetchedData?.status === 'active' ? 'active' : 'inactive'}
              fullWidth
              label="Status"
              size="small"
              disabled
            />
            <TextField
              value={fetchedData?.latitude ?? ''}
              label="Latitude"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.longitude ?? ''}
              label="Latitude"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.description ?? ''}
              label="Descrtipion"
              size="small"
              disabled
              fullWidth
              multiline
              rows={3}
            />
            <TextField
              value={fetchedData?.activated_at ?? ''}
              label="Activated Timestamp"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.deactivated_at ?? ''}
              label="Deactivated Timestamp"
              size="small"
              disabled
              fullWidth
            />
          </Box>
        </TabPanel>
        <TabPanel value="local_button_press" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={
                fetchedData?.local_button_press_action
                  ? localButtonPressAction?.find(
                      (it) => it.key === fetchedData?.local_button_press_action
                    )?.label
                  : ''
              }
              label="Action"
              size="small"
              disabled
              fullWidth
            />
            {(fetchedData?.local_button_press_action ===
              'activate_warning_group' ||
              fetchedData?.local_button_press_action ===
                'activate_alarm_group') && (
              <TextField
                value={
                  fetchedData?.local_button_press_action_group_id
                    ? alarm_groups?.find(
                        (it) =>
                          it.id ===
                          fetchedData?.local_button_press_action_group_id
                      )?.name || ''
                    : fetchedData?.local_button_press_action_zone_id
                      ? zones?.find(
                          (it) =>
                            it.id ===
                            fetchedData?.local_button_press_action_zone_id
                        )?.name || ''
                      : ''
                }
                label="Zone/Group"
                size="small"
                disabled
                fullWidth
              />
            )}
          </Box>
        </TabPanel>
      </TabContext>

      <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="start" gap={0.5}>
          {onOpenHistory && fetchedData && (
            <AccessControl
              permissions={[
                'get::/alarm-module/:id(\\d+)/history',
                'get::/alarm-module/:id(\\d+)/log',
              ]}
            >
              <AlarmItemHistoryButton
                setViewType={(type: 'alarm' | 'alarm_log') => {
                  onOpenHistory(fetchedData, type);
                }}
              >
                History
              </AlarmItemHistoryButton>
            </AccessControl>
          )}
          {fetchedData ? (
            <>
              {onAck && Number(fetchedData?.acknowledged) !== 1 ? (
                <AccessControl
                  permissions={['patch::/alarm-module/:id(\\d+)/acknowledge']}
                >
                  <Button onClick={() => onAck()}>Acknowledge</Button>
                </AccessControl>
              ) : null}
            </>
          ) : null}
          {(me?.type_id !== 5 ||
            getHasPermission(me.permissions, ['post::/purge'])) && (
            <AlarmItemActionsButton
              item={fetchedData}
              component={IconButton}
              componentProps={{size: 'small', color: 'primary'}}
              onSubmitted={() => {
                onSubmitted?.();
              }}
              onPurged={onPurged}
            >
              <MoreHorizIcon className="alarm_action_icon" />
            </AlarmItemActionsButton>
          )}
        </Box>
        <Box display="flex" justifyContent="end" gap={0.5}>
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          {onEdit ? (
            <AccessControl permissions={['patch::/alarm-module/:id(\\d+)']}>
              <Button onClick={() => onEdit()}>Edit Alarm</Button>
            </AccessControl>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default AlarmItemView;
