/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-empty */
import {DivIcon} from 'leaflet';
import _ from 'lodash';
import {Marker, Popup, Tooltip} from 'react-leaflet';
import {useSelector} from 'react-redux';

import {useConfigurations} from '../../../hooks/configuration';
import {useGetAmsIconName} from '../../../hooks/get-ams-icon-name';
import {useAppSelector} from '../../../hooks/redux';
import {AMSEModuleNode} from '../../../interfaces/AMSEModuleNode';
import {BeltMonitoringNode} from '../../../interfaces/BeltMonitoringNode';
import {CommtracNode} from '../../../interfaces/CommtracNode';
import {
  GasMonitoringNode,
  SentroType,
} from '../../../interfaces/GasMonitoringNode';
import {Node} from '../../../interfaces/Node';
import reduxSelectors from '../../../redux/selectors';
import {
  AMS_COLORS,
  AMS_EVENT_TYPES,
  amsNodeInAlarm,
  amsNodeInAlert,
} from '../../../utils/ams';
import {BELT_COLORS, BELT_EVENT_TYPES} from '../../../utils/belt';
import {
  ASSET_EVENT_COLORS,
  ASSET_EVENT_TYPES,
  MINER_EVENT_COLORS,
  MINER_EVENT_TYPES,
} from '../../../utils/commtrac-nodes';
import {COLORED_LEAFLET_ICONS, iconName} from '../../../utils/icons';
import {CN_EVENT_COLORS, CN_EVENT_TYPES} from '../../../utils/nodes';
import AMSSensorTooltipContent from './AMSSensorTooltipContent';
import BeltNodeTooltipContent from './BeltNodeTooltipContent';
import CommtracNodeTooltipContent from './CommtracNodeTooltipContent';
import EModuleTooltipContent from './EModuleTooltipContent';
import NodeTooltipContent from './NodeTooltipContent';
import TempItemTooltipContent from './TempItemTooltipContent';

const duplicateIcon = require('../../../images/other/duplicate_icon.png');

interface Props {
  type: 'employee' | 'asset' | 'node' | 'e_module' | 'ams_sensor' | 'belt';
  topRightPosition?: any | null;
  i: {
    key: string;
    items: any[];
  };
  disableEditMode?: boolean;
  onOpenItem?: (
    id: number,
    type:
      | 'cn'
      | 'wifi'
      | 'employee'
      | 'asset'
      | 'alarm'
      | 'e-module'
      | 'ams'
      | 'belt'
  ) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'asset'
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'employee'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
      | 'alarm'
      | 'alarm_log'
      | 'hazard_ai_detection_log'
      | 'hazard_ai_heatmap'
      | 'amsEmoduleInstallationHistory'
      | 'amsEmoduleSensorHistory'
      | 'amsEmoduleCalibration'
      | 'amsShortTerm'
      | 'amsLongTerm'
      | 'amsLocation'
      | 'beltHistoryReport'
      | 'beltLocationHistoryReport'
  ) => void;
}

const TempItemMarker = ({
  type,
  topRightPosition,
  i,
  disableEditMode,
  onOpenItem,
  onOpenHistory,
}: Props) => {
  //const typeIdLabels = useAppSelector(({assets}) => assets.emoduleTypeIdLabel);
  const configurations = useConfigurations();
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const ams_nodes = assets.ams_nodes;
  const getAmsIconName = useGetAmsIconName();

  const getEmployeeIcon = (item: CommtracNode) => {
    let color: string | number | undefined;
    if (item.e_209) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_NEW_MINER_DETECTED] ?? ''
        ]?.value;
    } else if (item.e_201) {
      color =
        configurations[MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_ALARM] ?? '']
          ?.value;
    } else if (item.e_221) {
      color =
        configurations[
          MINER_EVENT_COLORS[
            MINER_EVENT_TYPES.MINER_TOO_MANY_LOCATION_MESSAGES
          ] ?? ''
        ]?.value;
    } else if (item.e_202) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_NO_LOCATION] ?? ''
        ]?.value;
    } else if (item.e_226) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_INVALID_LAST_LOCATION] ??
            ''
        ]?.value;
    } else if (item.e_203) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_LOW_BATTERY] ?? ''
        ]?.value;
    } else if (item.e_204) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_NEW_MESSAGE] ?? ''
        ]?.value;
    } else if (item.e_233) {
      color =
        configurations[
          MINER_EVENT_COLORS[MINER_EVENT_TYPES.MINER_NOT_MOVING_STATE] ?? ''
        ]?.value;
    }
    return COLORED_LEAFLET_ICONS.miners[color ?? 'default'];
  };

  const getAssetIcon = (item: CommtracNode) => {
    let color: string | number | undefined;
    if (item.e_309) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_NEW_ASSET_DETECTED] ?? ''
        ]?.value;
    } else if (item.e_321) {
      color =
        configurations[
          ASSET_EVENT_COLORS[
            ASSET_EVENT_TYPES.ASSET_TOO_MANY_LOCATION_MESSAGES
          ] ?? ''
        ]?.value;
    } else if (item.e_301) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_NO_LOCATION] ?? ''
        ]?.value;
    } else if (item.e_326) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_INVALID_LAST_LOCATION] ??
            ''
        ]?.value;
    } else if (item.e_302) {
      color =
        configurations[
          ASSET_EVENT_COLORS[ASSET_EVENT_TYPES.ASSET_LOW_BATTERY] ?? ''
        ]?.value;
    }
    return COLORED_LEAFLET_ICONS.assetEvents[color ?? 'default'];
  };

  const getCommunicationNodeIcon = (item: Node, isUnAck: boolean = false) => {
    let color: string | number | undefined;
    if (item.e_109) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_NEW_CN_DETECTED] ?? '']
          ?.value;
    } else if (item.status === 'inactive') {
      color = 'gray';
    } else if (item.e_101) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_ASSIGNED_MASTER] ?? '']
          ?.value;
    } else if (item.e_121) {
      color =
        configurations[
          CN_EVENT_COLORS[CN_EVENT_TYPES.CN_TOO_MANY_HEARTBEATS] ?? ''
        ]?.value;
    } else if (item.e_110) {
      color =
        configurations[
          CN_EVENT_COLORS[CN_EVENT_TYPES.CN_PIC_VERSION_HAS_CHANGED] ?? ''
        ]?.value;
    } else if (item.e_111) {
      color =
        configurations[
          CN_EVENT_COLORS[CN_EVENT_TYPES.CN_CC1110_VERSION_HAS_CHANGED] ?? ''
        ]?.value;
    } else if (item.e_102) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_NO_HEARTBEAT] ?? '']
          ?.value;
    } else if (item.e_103) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_LOW_BATTERY] ?? '']
          ?.value;
    } else if (item.e_104) {
      color =
        configurations[CN_EVENT_COLORS[CN_EVENT_TYPES.CN_FEW_HEARTBEATS] ?? '']
          ?.value;
    }

    if (isUnAck === true) {
      color = 'gray';
    }
    return COLORED_LEAFLET_ICONS.communicationNodes[color ?? 'default'];
  };

  const getWifiIcon = (item: Node, isUnAck: boolean = false) => {
    let color: string | number | undefined;
    if (item.wifi_connection_status === 'inactive') {
      color = 'gray';
    } else if (item.wifi_connection_status === 'disconnected') {
      color = 'red';
    }
    if (isUnAck === true) {
      color = 'gray';
    }
    return COLORED_LEAFLET_ICONS.wifi[color ?? 'blue'];
  };

  const getNodeIcon = (
    item: Node,
    isUnAck: boolean = false,
    isDuplicatedMode: boolean = false
  ) => {
    if (isDuplicatedMode === true) {
      return COLORED_LEAFLET_ICONS.communicationNodes.duplicate;
    }
    if (item.node_type === 'communication_node') {
      return getCommunicationNodeIcon(item, isUnAck);
    } else if (item.node_type === 'wifi_point') {
      return getWifiIcon(item, isUnAck);
    }
    return COLORED_LEAFLET_ICONS.communicationNodes.default;
  };

  const getEModuleIcon = (item: AMSEModuleNode) => {
    let color: string | number;
    const associated_ams_node = ams_nodes?.find(
      (it) => `${it?.commtrac_external_id}` === `${item?.commtrac_external_id}`
    );
    if (item?.e_408) {
      color =
        configurations[
          AMS_COLORS[AMS_EVENT_TYPES.AMS_CALIBRATION_DATE_EXPIRED] ?? ''
        ]?.value ?? 'yellow';
    } else if (item?.e_410) {
      color =
        configurations[
          AMS_COLORS[AMS_EVENT_TYPES.AMS_EMODULE_UNREGISTERED] ?? ''
        ]?.value ?? 'yellow';
    } else if (associated_ams_node?.events.e_401) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_NEW_DETECTED] ?? '']
          ?.value ?? 'yellow';
    } else if (associated_ams_node?.events.e_402) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_ALARM] ?? '']?.value ??
        'red';
    } else if (associated_ams_node?.events.e_403) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_ALERT] ?? '']?.value ??
        'yellow';
    } else if (associated_ams_node?.events.e_404) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_SD_NO_ENVS_MESSAGE] ?? '']
          ?.value ?? 'yellow';
    } else if (associated_ams_node?.events.e_405) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_LOW_BATTERY] ?? '']
          ?.value ?? 'yellow';
    } else if (associated_ams_node?.events.e_406) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_FEW_ENV_MESSAGES] ?? '']
          ?.value ?? 'yellow';
    } else if (associated_ams_node?.events.e_407) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_MANY_ENV_MESSAGES] ?? '']
          ?.value ?? 'yellow';
    } else if (associated_ams_node?.events.e_408) {
      color =
        configurations[
          AMS_COLORS[AMS_EVENT_TYPES.AMS_CALIBRATION_DATE_EXPIRED] ?? ''
        ]?.value ?? 'yellow';
    } else if (associated_ams_node?.events.e_410) {
      color =
        configurations[
          AMS_COLORS[AMS_EVENT_TYPES.AMS_EMODULE_UNREGISTERED] ?? ''
        ]?.value ?? 'yellow';
    } else if (associated_ams_node?.events.e_411) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_ENV_MISSING_SN] ?? '']
          ?.value ?? 'yellow';
      /*} else if (associated_ams_node?.e_430) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_INPUT_STATE_CHANGE] ?? '']
          ?.value ?? 'yellow';*/
    } else if (associated_ams_node?.events.e_440) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_AVA_TEST] ?? '']?.value ??
        'yellow';
    } else if (associated_ams_node?.status === 'inactive') {
      color = 'gray';
    } else {
      color = 'blue';
    }

    return COLORED_LEAFLET_ICONS[getAmsIconName(item.type) as iconName][color];
  };

  const getAMSSensorIcon = (item: GasMonitoringNode) => {
    let color: string | number | undefined = 'blue';

    if (item?.events.e_408) {
      color =
        configurations[
          AMS_COLORS[AMS_EVENT_TYPES.AMS_CALIBRATION_DATE_EXPIRED] ?? ''
        ]?.value ?? 'yellow';
    } else if (item?.events.e_410) {
      color =
        configurations[
          AMS_COLORS[AMS_EVENT_TYPES.AMS_EMODULE_UNREGISTERED] ?? ''
        ]?.value ?? 'yellow';
    } else if (item?.events.e_401) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_NEW_DETECTED] ?? '']
          ?.value ?? 'yellow';
    } else if (amsNodeInAlarm(item)) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_ALARM] ?? '']?.value ??
        'red';
    } else if (amsNodeInAlert(item)) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_ALERT] ?? '']?.value ??
        'yellow';
    } else if (item?.events.e_404) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_SD_NO_ENVS_MESSAGE] ?? '']
          ?.value ?? 'yellow';
    } else if (item?.events.e_405) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_LOW_BATTERY] ?? '']
          ?.value ?? 'yellow';
    } else if (item?.events.e_406) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_FEW_ENV_MESSAGES] ?? '']
          ?.value ?? 'yellow';
    } else if (item?.events.e_407) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_MANY_ENV_MESSAGES] ?? '']
          ?.value ?? 'yellow';
    } else if (item?.events.e_411) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_ENV_MISSING_SN] ?? '']
          ?.value ?? 'yellow';
    } else if (item?.events.e_440) {
      color =
        configurations[AMS_COLORS[AMS_EVENT_TYPES.AMS_AVA_TEST] ?? '']?.value ??
        'yellow';
    } else if (item?.status === 'inactive') {
      color = 'gray';
    }

    const {type_id} = item;

    const icon =
      item.sentro_type === SentroType.SENTRO_8
        ? SentroType.SENTRO_8
        : getAmsIconName(type_id);

    return COLORED_LEAFLET_ICONS[icon as keyof typeof COLORED_LEAFLET_ICONS][
      color
    ];
  };

  const getBeltIcon = (item: BeltMonitoringNode) => {
    let color: string | number | undefined = 'blue';

    if (item?.e_601) {
      color =
        configurations[
          BELT_COLORS[BELT_EVENT_TYPES.BELT_NEW_BELT_DETECTED] ?? ''
        ]?.value ?? 'yellow';
    } else if (item?.e_602) {
      color =
        configurations[BELT_COLORS[BELT_EVENT_TYPES.BELT_INPUT_FAULT] ?? '']
          ?.value ?? 'yellow';
    } else if (item?.e_603) {
      color =
        configurations[BELT_COLORS[BELT_EVENT_TYPES.BELT_NO_ENV_MESSAGE] ?? '']
          ?.value ?? 'yellow';
    } else if (item?.e_604) {
      color =
        configurations[BELT_COLORS[BELT_EVENT_TYPES.BELT_FEW_ENV_MESSAGE] ?? '']
          ?.value ?? 'yellow';
    } else if (item?.e_605) {
      color =
        configurations[
          BELT_COLORS[BELT_EVENT_TYPES.BELT_MANY_ENV_MESSAGE] ?? ''
        ]?.value ?? 'yellow';
    } else if (item?.e_606) {
      color =
        configurations[BELT_COLORS[BELT_EVENT_TYPES.BELT_STOPPED] ?? '']
          ?.value ?? 'yellow';
    } else if (item?.e_607) {
      color =
        configurations[
          BELT_COLORS[BELT_EVENT_TYPES.BELT_CONFIGURATION_FAILED] ?? ''
        ]?.value ?? 'yellow';
    } else if (item?.e_610 || item?.e_611) {
      color =
        configurations[
          BELT_COLORS[BELT_EVENT_TYPES.BELT_OUTPUT_STATUS_CHANGED] ?? ''
        ]?.value ?? 'yellow';
    } else if (item?.status === 'active') {
      color = 'healthy';
    } else if (item?.status === 'inactive') {
      color = 'inactive';
    } else {
      color = 'blue';
    }
    return COLORED_LEAFLET_ICONS.belts[color ?? 'default'];
  };

  return (
    <Marker
      position={
        i.items[0]?.item_type === 'new_devices'
          ? topRightPosition
            ? topRightPosition
            : [0, 0]
          : i.items[0]?.item_type === 'employee' ||
              i.items[0]?.item_type === 'asset'
            ? [
                i.items[0].commtrac_pos_lat ?? 0,
                i.items[0].commtrac_pos_lon ?? 0,
              ]
            : [i.items[0].latitude ?? 0, i.items[0].longitude ?? 0]
      }
      icon={
        i.items.length === 1
          ? type === 'employee'
            ? getEmployeeIcon(i.items[0])
            : type === 'asset'
              ? getAssetIcon(i.items[0])
              : type === 'e_module'
                ? getEModuleIcon(i.items[0])
                : type === 'ams_sensor'
                  ? getAMSSensorIcon(i.items[0])
                  : type === 'belt'
                    ? getBeltIcon(i.items[0])
                    : getNodeIcon(
                        i.items[0],
                        i.items[0].item_type === 'new_devices'
                      )
          : new DivIcon({
              className: 'duplicate icon',
              html: `<img src="${duplicateIcon}" /><span style="color: #FFF">${i.items.length}</span>`,
              iconAnchor: [16, 37],
            })
      }
      interactive
      eventHandlers={{
        click: (event) => event.target.openPopup(),
        popupopen: (event) => {
          event.target._tooltip.options.opacity = 0;
        },
        popupclose: (event) => {
          event.target._tooltip.options.opacity = 0.9;
        },
      }}
    >
      <Popup interactive autoClose={false} offset={[0, -32]}>
        {i.items?.length === 1 ? (
          <>
            {i.items[0]?.item_type === 'node' ? (
              <NodeTooltipContent
                item={i.items[0]}
                items={i.items}
                disableEditMode={disableEditMode}
                onOpenItem={(item) => {
                  if (!_.isEmpty(item)) {
                    if (item.node_type === 'communication_node' && item.cn_id) {
                      onOpenItem?.(item.cn_id, 'cn');
                    } else if (
                      item.node_type === 'wifi_point' &&
                      item.wifi_id
                    ) {
                      onOpenItem?.(item.wifi_id, 'wifi');
                    }
                  } else if (_.isEmpty(item)) {
                    if (
                      i.items[0].node_type === 'communication_node' &&
                      i.items[0].cn_id
                    ) {
                      onOpenItem?.(i.items[0].cn_id, 'cn');
                    } else if (
                      i.items[0].node_type === 'wifi_point' &&
                      i.items[0].wifi_id
                    ) {
                      onOpenItem?.(i.items[0].wifi_id, 'wifi');
                    }
                  }
                }}
                onOpenHistory={(item) => {
                  if (!_.isEmpty(item)) {
                    if (
                      item.node_type === 'communication_node' &&
                      item.cn_external_id
                    ) {
                      onOpenHistory?.(item.cn_external_id, 'commtracNodeByCn');
                    }
                  } else if (_.isEmpty(item)) {
                    if (
                      i.items[0].node_type === 'communication_node' &&
                      i.items[0].cn_external_id
                    ) {
                      onOpenHistory?.(
                        i.items[0].cn_external_id,
                        'commtracNodeByCn'
                      );
                    }
                  }
                }}
              />
            ) : i.items[0]?.item_type === 'e_module' ? (
              <EModuleTooltipContent
                item={i.items[0]}
                items={i.items}
                onOpenItem={onOpenItem}
                onOpenHistory={onOpenHistory}
              />
            ) : i.items[0]?.item_type === 'ams_sensor' ? (
              <AMSSensorTooltipContent
                item={i.items[0]}
                items={i.items}
                onOpenItem={onOpenItem}
                onOpenHistory={onOpenHistory}
              />
            ) : i.items[0]?.item_type === 'belt' ? (
              <BeltNodeTooltipContent
                item={i.items[0]}
                items={i.items}
                onOpenItem={onOpenItem}
                onOpenHistory={onOpenHistory}
              />
            ) : (
              <CommtracNodeTooltipContent
                item={i.items[0]}
                items={i.items}
                disableEditMode={disableEditMode}
                onOpenItem={(item) => {
                  if (item) {
                    onOpenItem?.(
                      item?.employee_asset_id ?? -1,
                      i.items[0].item_type
                    );
                  } else {
                    onOpenItem?.(
                      i.items[0].employee_asset_id ?? -1,
                      i.items[0].item_type
                    );
                  }
                }}
              />
            )}
          </>
        ) : (
          <>
            <TempItemTooltipContent items={i.items}></TempItemTooltipContent>
          </>
        )}
      </Popup>

      <Tooltip direction="top" offset={[0, -34]} pane="popupPane">
        {i.items?.length === 1 ? (
          <>
            {i.items[0]?.item_type === 'node' ? (
              <NodeTooltipContent
                item={i.items[0]}
                items={i.items}
                disableEditMode={disableEditMode}
                onOpenItem={(item) => {
                  if (!_.isEmpty(item)) {
                    if (item.node_type === 'communication_node' && item.cn_id) {
                      onOpenItem?.(item.cn_id, 'cn');
                    } else if (
                      item.node_type === 'wifi_point' &&
                      item.wifi_id
                    ) {
                      onOpenItem?.(item.wifi_id, 'wifi');
                    }
                  } else if (_.isEmpty(item)) {
                    if (
                      i.items[0].node_type === 'communication_node' &&
                      i.items[0].cn_id
                    ) {
                      onOpenItem?.(i.items[0].cn_id, 'cn');
                    } else if (
                      i.items[0].node_type === 'wifi_point' &&
                      i.items[0].wifi_id
                    ) {
                      onOpenItem?.(i.items[0].wifi_id, 'wifi');
                    }
                  }
                }}
                onOpenHistory={(item) => {
                  if (!_.isEmpty(item)) {
                    if (
                      item.node_type === 'communication_node' &&
                      item.cn_external_id
                    ) {
                      onOpenHistory?.(item.cn_external_id, 'commtracNodeByCn');
                    }
                  } else if (_.isEmpty(item)) {
                    if (
                      i.items[0].node_type === 'communication_node' &&
                      i.items[0].cn_external_id
                    ) {
                      onOpenHistory?.(
                        i.items[0].cn_external_id,
                        'commtracNodeByCn'
                      );
                    }
                  }
                }}
              />
            ) : i.items[0]?.item_type === 'e_module' ? (
              <EModuleTooltipContent
                item={i.items[0]}
                items={i.items}
                onOpenItem={onOpenItem}
                onOpenHistory={onOpenHistory}
              />
            ) : i.items[0]?.item_type === 'ams_sensor' ? (
              <AMSSensorTooltipContent
                item={i.items[0]}
                items={i.items}
                onOpenItem={onOpenItem}
                onOpenHistory={onOpenHistory}
              />
            ) : i.items[0]?.item_type === 'belt' ? (
              <BeltNodeTooltipContent
                item={i.items[0]}
                items={i.items}
                onOpenItem={onOpenItem}
                onOpenHistory={onOpenHistory}
              />
            ) : (
              <CommtracNodeTooltipContent
                item={i.items[0]}
                items={i.items}
                disableEditMode={disableEditMode}
                onOpenItem={(item) => {
                  if (item) {
                    onOpenItem?.(
                      item?.employee_asset_id ?? -1,
                      i.items[0].item_type
                    );
                  } else {
                    onOpenItem?.(
                      i.items[0].employee_asset_id ?? -1,
                      i.items[0].item_type
                    );
                  }
                }}
              />
            )}
          </>
        ) : (
          <>
            <TempItemTooltipContent items={i.items}></TempItemTooltipContent>
          </>
        )}
      </Tooltip>
    </Marker>
  );
};

export default TempItemMarker;
