import { Box, Button, ButtonGroup } from "@mui/material";
import { isEqual } from "lodash";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { ShaftClearanceBarData, ShaftClearanceEmployeesListQuery,ShaftClearancePieData} from "../../../interfaces/ShaftClearance";
import reduxSelectors from "../../../redux/selectors";
import { BarItem, BarRef, BarView } from "./BarView";
import { PieItem, PieRef, PieView } from "./PieView";

interface Props {
  view: string;
  pieData?: ShaftClearancePieData;
  barData?: ShaftClearanceBarData;
  params: ShaftClearanceEmployeesListQuery;
  isMinersorAsset: string;
  isLoading: boolean;
  onChangeFilter: (v: string, key: string) => void;
  onChangeChartViewMode?: (v: string) => void;
}

export interface ChartRef {
  updateChart?: Function;
}

export const Chart = forwardRef<ChartRef, Props>(
  ({
    view,
    pieData,
    barData,
    params,
    isMinersorAsset,
    isLoading,
    onChangeFilter,
    onChangeChartViewMode,
  }, ref) => {
  const pieRef = useRef<PieRef>(null);
  const barRef = useRef<BarRef>(null);
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const prevSectionDataRef = useRef<PieItem[]>([]);
  const prevLocationDataRef = useRef<PieItem[]>([]);
  const [viewMode, setViewMode] = useState<string>(view);

  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    updateChart: () => updateGraph()
  }));

  const updateGraph = () => {
    if (view === 'pie') {
      pieRef?.current?.updatePieChart?.();
    } else {
      barRef?.current?.updateBarGraph?.();
    }
  }

  const generateLocationPieData = (data?: ShaftClearancePieData): PieItem[]  => {
    const result: PieItem[] = [];
    if (!data) {
      return [];
    }

    const dataByLocation = data.data.find(item => item.category_id === "location");
    dataByLocation?.categories.map((category: any) => {
      return dataByLocation.data.find((item: any) => {
        if (item.category_id === category.id && item.value > 0) {
          result.push({
            name: category.id,
            y: item.value
          })
        }
      })
    });
    return result;
  };

  const generateSectionPieData = (data?: ShaftClearancePieData): PieItem[]  => {
    const result: PieItem[] = [];
    if (!data) {
      return [];
    }

    const dataByLocation = data.data.find(item => item.category_id === "section");
    dataByLocation?.categories.map((category: any) => {
      return dataByLocation.data.find((item: any) => {
        if (item.category_id === category.id && item.value > 0) {
          result.push({
            zone_id: assets.zones.find((it) => it.name === category.label)?.id,
            name: category.label,
            y: item.value,
          })
        }
      })
    });

    return result;
  };

  const generateBarData = (data?: ShaftClearanceBarData): BarItem[]  => {
    const result: BarItem[] = [];
    if (!data) {
      return [];
    } else if (!params.location && !params.section_id && view === 'bar') {
      const dataByLocation = pieData?.data.find(item => item.category_id === "location");
      dataByLocation?.categories.map((category: any) => {
        return dataByLocation.data.find((item: any) => {
          if (item.category_id === category.id && item.value > 0) {
            result.push({
              name: category.label,
              data: item.value
            })
          }
        })
      });
    } else {
      data?.categories.map((category: any) => {
        return data.data.find((item: any) => {
          if (item.category_id === category.id && item.value > 0) {
            result.push({
              data: item.value,
              name: category.label
            })
          }
        })
      });
    }
    return result;
  };

  const sectionData = useMemo(() => {
    if (view === 'pie') {
        const newData = generateSectionPieData(pieData);
        if (isEqual(newData, prevSectionDataRef.current)) {
          return prevSectionDataRef.current;
        }
        prevSectionDataRef.current = newData;
        return newData;
    }
    return [];
  }, [view, pieData]);

  const locationData = useMemo(() => {
    if (view === 'pie') {
      const newData = generateLocationPieData(pieData);
      if (isEqual(newData, prevLocationDataRef.current)) {
        return prevLocationDataRef.current;
      }
      prevLocationDataRef.current = newData;
      return newData;
    }
    return [];
  }, [view, pieData]);

  const graphData = useMemo(() => {
    return generateBarData(barData);
  }, [view, pieData, barData]);

  useEffect(() => {
    setViewMode(view);
  }, [view]);

  const getChartTitle = () => {
    if (!params.location && !params.section_id) {
      return (
        <Box className="title">
          {`Amount of ${isMinersorAsset} per Location`}
          <Box className="sub_title" >&nbsp;</Box>
        </Box>
      )
    } else if (!params.location && params.section_id) {
      const zone = assets.zones.find((zone) => zone.id === params.section_id);
      return (
        <Box className="title">
          {`Amount of ${isMinersorAsset} per CN in Section`}
          <Box className="sub_title" >Section: {zone?.name}</Box>
        </Box>
      )
    } else if (params.location && !params.section_id) {
      return (
        <Box className="title">
          {`Amount of ${isMinersorAsset} per Section`}
          <Box className="sub_title" >Location: {!params.section_id && (params.location)}</Box>
        </Box>
      )
    } else if (params.location && params.section_id) {
      const zone = assets.zones.find((zone) => zone.id === params.section_id);
      return (
        <Box className="title">
          {`Amount of ${isMinersorAsset} per CN in Section`}
          <Box className="sub_title">Section: {zone?.name}</Box>
        </Box>
      )
    }
  }

  const isNotEmptyData = () => {
    if (viewMode === 'pie') {
      return locationData.length > 0;
    } else {
      return graphData.length > 0;
    }
  }


  useEffect(() => {
    setViewMode(view);
  }, [view])

  return (
    <Box
      className="chart"
      sx={{
        position: 'relative',
        height: `100%`,
      }}
    >
      {isNotEmptyData() && (
        <Box
          sx={{
            '& .title': {
              fontSize: '20px',
              textAlign: 'center',
              marginBottom: '10px'
            },
            '& .sub_title': {
              fontSize: '14px',
              textAlign: 'center',
              marginBottom: '10px'
            },
            "@media (max-width: 462)": {
              '& .title': {
                fontSize: '16px',
                textAlign: 'center',
                marginBottom: '10px'
              },
              '& .sub_title': {
                fontSize: '8px',
                textAlign: 'center',
                marginBottom: '10px'
              },
            }
          }}
        >
          {getChartTitle()}
        </Box>
      )}
      {!params?.section_id && isNotEmptyData() && (
        <Box paddingLeft={2}>
          <ButtonGroup>
            <Button
              size="small"
              variant={viewMode === 'pie' ? 'contained' : 'outlined'}
              onClick={() => {
                setViewMode('pie');
                onChangeChartViewMode?.('pie');
              }}
            >
              Pie
            </Button>

            <Button
              size="small"
              variant={viewMode === 'bar' ? 'contained' : 'outlined'}
              onClick={() => {
                setViewMode('bar');
                onChangeChartViewMode?.('bar');
              }}
            >
              Bar
            </Button>
          </ButtonGroup>
        </Box>
      )}
      {viewMode === 'pie' && !isLoading && (
        <PieView
          ref={pieRef}
          locationData={locationData}
          sectionData={sectionData}
          location={params?.location}
          onChangeFilter={(v, filterType) => onChangeFilter(v, filterType)}
        />
      )}
      {viewMode === 'bar' && (
        <BarView
          ref={barRef}
          params={params}
          data={graphData}
          onChangeFilter={(v, filterType) => onChangeFilter(v, filterType)}
        />
      )}
    </Box>
    )
  }
);

