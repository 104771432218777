export const COMMUNICATION_NODE_TYPES = [
  {
    id: 1,
    name: 'Gateway Master',
  },
  {
    id: 2,
    name: 'Gateway Slave',
  },
  {
    id: 3,
    name: 'Standard Node',
  },
] as const;

export const WIFI_POINT_TYPES = [
  {
    key: 'A',
    name: 'Access Point',
  },
  {
    key: 'B',
    name: 'Bluetooth Beacon',
  },
  {
    key: 'D',
    name: 'Type D',
  },
] as const;

export const NODE_TYPES = [
  {
    key: 'communication_node',
    name: 'Communication Nodes',
  },
  {
    key: 'wifi_point',
    name: 'WiFi Nodes',
  },
] as const;

export const ASSET_TYPES = [
  {
    key: 'asset',
    name: 'Assets',
  },
  {
    key: 'miner',
    name: 'Miners',
  },
] as const;

export const AMS_SENTRO_TYPES = [
  {
    key: 'sentro_1',
    name: 'Sentro 1',
  },
  {
    key: 'sentro_8',
    name: 'Sentro 8',
  },
] as const;
